import { useSelector } from "react-redux";

import { isUsernamesFromQuery } from "Helpers/utils";
import { IUser, IUserExtended } from "SP/users/users.types";
import { getCurrentUser } from "Store/actions/users.actions";
import { IRootReducerState } from "Store/reducers";
import { useAppDispatch } from "Store/store";

export const useAllUsersData = () => {
  const allUsers = useSelector<IRootReducerState, IUser[]>((state) => state.users.allUsers);

  const getUsersByIds = (ids: (number | string)[]) => {
    const isFromQuery = isUsernamesFromQuery(ids);
    return allUsers.filter((user) => ids.includes(isFromQuery ? user.name : user.id));
  };

  return {
    allUsers,
    getUsersByIds,
  };
};

export const useUsersData = () => {
  const currentUser = useSelector<IRootReducerState, IUserExtended>((state) => state.users.currentUser);

  return {
    currentUser,
  };
};

export const useUsersMethods = () => {
  const dispatch = useAppDispatch();

  const handleGetCurrentUser = () => dispatch(getCurrentUser());

  return {
    getCurrentUser: handleGetCurrentUser,
  };
};
