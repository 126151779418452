import _uniq from "lodash/uniq";
import { all, call, CallEffect, fork, ForkEffect, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import { getFavoritesOrder, updateCurrentUserFavoritesOrder } from "@App/sagas/helpers/utils.sagas";
import { loadUsersByIds } from "@App/sagas/users.sagas";

import { getDecodedReportName, getReportVideoGuideFromStore } from "Helpers/reportsHelper";
import { getUsersByIds } from "Helpers/userHandler";
import { handleArrayChecker, isNumber } from "Helpers/utils";
import { setAllReportsOwners } from "Pages/reports-browser/reports-browser.actions";
import ReportsService from "SP/reports";
import { IReportExtended, IReportPermissions, IReportVersion } from "SP/reports/reports.types";
import { convertTagIdsToTagsInReport, isReportHasUniquePermissions } from "SP/reports/reports.utils";
import { IUserExtended } from "SP/users/users.types";
import { notificationTypes, showNotification } from "Store/actions/notifications.actions";
import { getPermissionsOnReportSuccess } from "Store/actions/permissions.actions";
import { UsersActionsTypes } from "Store/actions/users.actions";
import { IRootReducerState } from "Store/reducers";

import {
  createReportFailure,
  createReportSuccess,
  deleteReportFailure,
  deleteReportSuccess,
  editReportFailure,
  editReportSuccess,
  getReportFailure,
  getReportFileUrlFailure,
  getReportFileUrlSuccess,
  getReportSuccess,
  getReportVersionHistoryFailure,
  getReportVersionHistorySuccess,
  getReportVideoGuideFailure,
  getReportVideoGuideSuccess,
  ICreateReportRequestAction,
  IDeleteReportRequestAction,
  IEditReportRequestAction,
  IGetReportFileUrlRequestAction,
  IGetReportRequestAction,
  IGetReportVersionHistoryRequestAction,
  IGetReportVideoGuideRequestAction,
  ReportActionTypes,
} from "./report.actions";

const reportsService = new ReportsService();

function* loadSingleReport(action: IGetReportRequestAction) {
  try {
    const decodedIdentifier = getDecodedReportName(action.reportIdentifier);
    const identifierType = isNumber(decodedIdentifier) ? "id" : "name";
    const report: IReportExtended = yield call([reportsService, "getByIdentifier"], decodedIdentifier, identifierType);

    const reportPermissions = yield call([reportsService, "getPermissionsOnItem"], report.id);
    const reportUsersIds = _uniq([
      ...handleArrayChecker(report.owner),
      ...handleArrayChecker(report.viewOnlyUsers),
      ...handleArrayChecker(report.readOnlyUsers),
      ...handleArrayChecker(report.modifierUsers),
      ...handleArrayChecker(report.contributorUsers),
    ]);

    if (reportUsersIds.length > 0) {
      const users = yield call(loadUsersByIds, {
        type: UsersActionsTypes.GET_USERS_BY_IDS_REQUEST,
        ids: reportUsersIds,
      });

      const owners = getUsersByIds(users, handleArrayChecker(report.owner));
      report.ownersNames = owners.map((owner) => owner.name);

      // In case if new owners were added update the list
      yield put(setAllReportsOwners(owners));
    }

    yield put(getReportSuccess(report, reportPermissions));

    // Store file and permissions for report with uploaded file
    // to prevent double requests when user hover on file icon
    if (!report.reportLink) {
      yield put(getReportFileUrlSuccess(report.file, report.id));
      yield put(getPermissionsOnReportSuccess(report.id, reportPermissions));
    }
  } catch (e: any) {
    yield put(getReportFailure(e));
    throw e;
  }
}

function* watchLoadSingleReport() {
  yield takeLatest(ReportActionTypes.GET_REPORT_REQUEST, loadSingleReport);
}

function* editSingleReport(action: IEditReportRequestAction) {
  try {
    const currentUser: IUserExtended = yield select((state: IRootReducerState) => state.users.currentUser);
    const permissions: IReportPermissions = yield select((state: IRootReducerState) => state.report.permissions);
    const oldReportData: IReportExtended = yield select((state: IRootReducerState) => state.report.savedReport);
    const reportData = convertTagIdsToTagsInReport({ ...action.payload });

    if (
      isReportHasUniquePermissions(reportData) &&
      permissions.canDelete &&
      !reportData.contributorUsers.includes(currentUser.id)
    ) {
      // SP add contributor role to the editor of report, we just update UI
      reportData.contributorUsers = [...reportData.contributorUsers, currentUser.id];
    }

    yield call([reportsService, "edit"], action.id, reportData, oldReportData, permissions.canDelete);
    yield put(editReportSuccess());
    yield put(
      showNotification({
        type: notificationTypes.success,
        text: "The report was successfully edited",
      }),
    );

    if (action.callback) {
      action.callback(reportData.reportName);
    }
  } catch (e: any) {
    yield put(editReportFailure(e));
    throw e;
  }
}

function* watchEditSingleReport() {
  yield takeLatest(ReportActionTypes.EDIT_REPORT_REQUEST, editSingleReport);
}

function* deleteSingleReport(action: IDeleteReportRequestAction) {
  try {
    const favoriteOrders = yield call(getFavoritesOrder);
    const batchRequests: CallEffect<string | void>[] = [call([reportsService, "deleteById"], action.reportId)];

    if (favoriteOrders.includes(action.reportId)) {
      batchRequests.push(
        call(
          updateCurrentUserFavoritesOrder,
          favoriteOrders.filter((id) => id !== action.reportId),
        ),
      );
    }

    yield all(batchRequests);
    yield put(deleteReportSuccess(action.reportId));
    yield put(
      showNotification({
        type: notificationTypes.success,
        text: "The report was successfully deleted",
      }),
    );

    if (action.callback) {
      action.callback();
    }
  } catch (e) {
    yield put(deleteReportFailure());
    throw e;
  }
}

function* watchDeleteSingleReport() {
  yield takeLatest(ReportActionTypes.DELETE_REPORT_REQUEST, deleteSingleReport);
}

function* createReport(action: ICreateReportRequestAction) {
  try {
    const currentUser: IUserExtended = yield select((state: IRootReducerState) => state.users.currentUser);
    const reportData = convertTagIdsToTagsInReport({ ...action.payload });

    if (isReportHasUniquePermissions(reportData)) {
      // SP add contributor role to the creator of report, we just update UI
      reportData.contributorUsers = [...reportData.contributorUsers, currentUser.id];
    }

    if (action.reportMode === 1) {
      yield call([reportsService, "createLink"], reportData);
    } else {
      yield call([reportsService, "create"], reportData);
    }
    yield put(createReportSuccess());
    yield put(
      showNotification({
        type: notificationTypes.success,
        text: "The report was successfully created",
      }),
    );

    if (action.callback) {
      action.callback(reportData.reportName);
    }
  } catch (e: any) {
    yield put(createReportFailure(e));
    throw e;
  }
}

function* watchCreateSingleReport() {
  yield takeLatest(ReportActionTypes.CREATE_REPORT_REQUEST, createReport);
}

function* getReportFileUrl(action: IGetReportFileUrlRequestAction) {
  try {
    const file = yield call([reportsService, "getFileInfo"], action.id);
    yield put(getReportFileUrlSuccess(file, action.id));
  } catch (error: any) {
    yield put(getReportFileUrlFailure(error));
    throw error;
  }
}

function* watchGetReportFileUrl() {
  yield takeEvery(ReportActionTypes.GET_REPORT_FILE_URL_REQUEST, getReportFileUrl);
}

function* getReportVideoGuide(action: IGetReportVideoGuideRequestAction) {
  try {
    const allReports = yield select((state: IRootReducerState) => state.reports.allReports);

    let reportVideoGuide = getReportVideoGuideFromStore(action.reportId, allReports);
    if (!reportVideoGuide) {
      reportVideoGuide = yield call([reportsService, "getReportVideoGuideById"], action.reportId);
    }
    yield put(getReportVideoGuideSuccess(reportVideoGuide));
  } catch (e: any) {
    yield put(getReportVideoGuideFailure(e));
    throw e;
  }
}

function* watchGetReportVideoGuide() {
  yield takeLatest(ReportActionTypes.GET_REPORT_VIDEO_GUIDE_REQUEST, getReportVideoGuide);
}

function* getReportVersionHistory(action: IGetReportVersionHistoryRequestAction) {
  try {
    let versions: IReportVersion[] = yield select((state: IRootReducerState) => state.report.versions);

    if (!versions) {
      versions = yield call([reportsService, "getReportVersionHistory"], action.reportId);
    }
    yield put(getReportVersionHistorySuccess(versions));
  } catch (e: any) {
    yield put(getReportVersionHistoryFailure(e));
    throw e;
  }
}

function* watchGetReportVersionHistory() {
  yield takeLatest(ReportActionTypes.GET_REPORT_VERSION_HISTORY_REQUEST, getReportVersionHistory);
}

export default function* singleReportSagas(): Iterator<ForkEffect> {
  yield fork(watchLoadSingleReport);
  yield fork(watchEditSingleReport);
  yield fork(watchDeleteSingleReport);
  yield fork(watchCreateSingleReport);
  yield fork(watchGetReportFileUrl);
  yield fork(watchGetReportVideoGuide);
  yield fork(watchGetReportVersionHistory);
}
