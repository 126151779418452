import { INotificationObject, showNotification as showNotificationAction } from "Store/actions/notifications.actions";
import { useAppDispatch } from "Store/store";

const useNotifications = () => {
  const dispatch = useAppDispatch();
  const showNotification = (notification: INotificationObject) => dispatch(showNotificationAction(notification));

  return {
    showNotification,
  };
};

export default useNotifications;
