import { FC, useEffect, useMemo } from "react";

import { ReactComponent as loadIcon } from "@App/assets/circle_loader_30 .svg";
import { FlexRow, LinkButton, Text } from "@epam/loveship";
import { IconContainer } from "@epam/uui-components";

import type { INotFoundProps } from "./select.types";

export const NotFoundBlock: FC<INotFoundProps> = ({
  search,
  searchLoading,
  checkCaption,
  autoCheck,
  getData,
  onCheck,
  onClose,
}) => {
  const data = useMemo(() => getData(search), [search, getData]);

  useEffect(() => {
    if (autoCheck && data.length > 1) {
      onCheck(data, onClose);
    }
  }, []);

  let content = <Text>No Results found</Text>;

  if (searchLoading) {
    content = (
      <>
        <IconContainer icon={loadIcon} size={40} /> <Text>Loading ...</Text>
      </>
    );
  }

  if (search && data.length > 1) {
    content = (
      <LinkButton
        {...(searchLoading ? { icon: loadIcon, iconPosition: "right", isDisabled: true } : {})}
        onClick={() => onCheck(data, onClose)}
        caption={checkCaption || "Check options"}
      />
    );
  }

  return <FlexRow cx="not-found-alert">{content}</FlexRow>;
};
