import { FC, useState } from "react";
import { useHistory } from "react-router-dom";

import Routes from "@App/routes";
import { SearchInput } from "@epam/loveship";

import withNaming from "Helpers/bemClassname";
import { useAppSelector } from "Hooks/useAppSelector";
import { useMobileData } from "Hooks/useMobile";
import { TagGroupNames } from "SP/tags/tags.types";

import DashboardDomainFilters from "../dashboard-domain-filters";
import { isNotDefaultFilterRole } from "../dashboard-domain-filters/dashboard-domain-filters";
import { useTagsContext } from "../tags-context";

import "./dashboard-domain-search.scss";

const cn = withNaming("dashboard-domain-search");

const DashboardDomainSearch: FC = () => {
  const filterRoles = useAppSelector((state) => state.dashboard.filterRoles);
  const { roles } = useTagsContext();

  const [value, setValue] = useState(null);
  const { isMobile } = useMobileData();

  const { push } = useHistory();

  const handleSubmit = (e) => {
    if (e.keyCode !== 13) return;

    let search = `search=${encodeURIComponent(value)}`;
    if (isNotDefaultFilterRole(filterRoles)) {
      filterRoles.forEach((filterRole) => {
        search += `&${TagGroupNames.Role}=${filterRole}`;
      });
    }

    push({
      pathname: Routes.REPORTS_BROWSER.path,
      search,
    });
  };

  const onCancel = () => {
    setValue("");
  };

  return (
    <div className={cn("", ["flex items-start"])}>
      <DashboardDomainFilters roles={roles} />
      <SearchInput
        autoFocus={!isMobile}
        disableDebounce
        cx={cn("input")}
        value={value}
        onValueChange={setValue}
        placeholder="Search report by name, description and tags"
        onKeyDown={handleSubmit}
        {...(value && { onCancel })}
      />
    </div>
  );
};

export default DashboardDomainSearch;
