import _isEqual from "lodash/isEqual";
import React from "react";

import { Text } from "@epam/loveship";
import { DataColumnProps } from "@epam/uui-core";

import { DEFAULT_DATE_FORMAT } from "Components/fields/datepicker-field";
import withNaming from "Helpers/bemClassname";
import dayjs from "Helpers/dayjsSetup";
import type { IReportVersion } from "SP/reports/reports.types";

enum RenderLabels {
  orderNumber = "Order No",
  description = "Description",
  userDefinedModificationDate = "Modification date",
  reportName = "Report Name",
  owner = "Owner",
  viewOnlyUsers = "View-only Users",
  readOnlyUsers = "Read-only Users",
  modifierUsers = "Modifiers",
  contributorUsers = "Contributors",
  certified = "Certified",
  kbLink = "KB Link",
  videoGuideLink = "Report Video Guide",
  reportLink = "Report Link",
  tags = "Tags",
  powerBI_ID = "Power BI ID",
  filePath = "Report Location",
  isTechnical = "Technical",
  primaryRoles = "Primary Roles",
}

export const MODIFIED_DATE_FORMAT = "M/D/YYYY HH:mm A";
export const DIFF_IGNORE_KEYS = ["fileSize", "editor", "modified", "versionLabel"];

export const RenderContent = {
  tags: (v: string[]) => v.join("; "),
  userDefinedModificationDate: (v: string) => dayjs.utc(v).format(DEFAULT_DATE_FORMAT),
  certified: (v: boolean) => (v ? "On" : "Off"),
  orderNumber: (v: number) => v.toLocaleString(),
};

export const getDiff = (currVersion: IReportVersion, prevVersion: IReportVersion): Partial<IReportVersion> => {
  return Object.entries(currVersion).reduce((diff, [key, value]) => {
    if (DIFF_IGNORE_KEYS.includes(key) || _isEqual(prevVersion?.[key], value)) {
      return diff;
    }

    return { ...diff, [key]: value };
  }, {});
};

export const renderFileSizeInKB = (fileSize: string): string => {
  const fileSizeInKB = Math.round((+fileSize / 1024) * 10) / 10;

  if (fileSizeInKB < 1) {
    return "< 1 KB";
  }

  return fileSizeInKB + " KB";
};

export const cn = withNaming("version-history");

export const getVersionColumns = (isMobile: boolean): DataColumnProps<IReportVersion>[] => {
  const textFontSize = isMobile ? "12" : "14";

  return [
    {
      key: "versionLabel",
      caption: "No.",
      render: (version) => <Text fontSize={textFontSize}>{version.versionLabel}</Text>,
      isSortable: true,
      isAlwaysVisible: true,
      width: isMobile ? 90 : 78,
    },
    {
      key: "modified",
      caption: "Modified",
      render: (version) => (
        <Text fontSize={textFontSize} color="info">
          {dayjs(version.modified).format(MODIFIED_DATE_FORMAT)}
        </Text>
      ),
      width: 224,
    },
    {
      key: "editor",
      caption: "Modified By",
      render: (version) => <Text fontSize={textFontSize}>{version.editor}</Text>,
      width: 224,
    },
    {
      key: "fileSize",
      caption: "Size",
      render: (version) => <Text fontSize={textFontSize}>{renderFileSizeInKB(version.fileSize)}</Text>,
      width: isMobile ? 100 : 156,
    },
  ];
};

export const renderDiff = (currVersion: IReportVersion, prevVersion: IReportVersion): React.ReactElement => {
  const diff = getDiff(currVersion, prevVersion);

  return (
    <div className={cn("diff-container", ["flex flex-col"])}>
      {Object.keys(diff).map((key) => {
        const value = diff[key];
        if (value === null) return null;

        let content = value;

        if (RenderContent[key]) {
          content = RenderContent[key](value);
        } else if (Array.isArray(value)) {
          content = value.map((item) => (
            <div key={item} className={cn("diff-item-content-li")}>
              {item}
            </div>
          ));
        }

        return (
          <div key={key} className={cn("diff-item", ["flex"])}>
            <div className={cn("diff-item-label")}>{RenderLabels[key]}</div>
            <div className={cn("diff-item-content")}>{content}</div>
          </div>
        );
      })}
    </div>
  );
};
