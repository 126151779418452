import { createBrowserHistory } from "history";
//
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";

import { ErrorHandler } from "@epam/uui";
import { Modals } from "@epam/uui-components";
import { DragGhost, HistoryAdaptedRouter, useUuiServices, UuiContext } from "@epam/uui-core";

import Notifications from "Components/notifications";
import store from "Store/store";

import { App } from "./App";
import { withAuth } from "./hoc/withAuth";
import { svc } from "./services";
import * as serviceWorker from "./serviceWorker";

import "@epam/uui-components/styles.css";
import "@epam/uui/styles.css";
import "@epam/assets/theme/theme_loveship.scss";
import "./scss/index.scss";

const history = createBrowserHistory();
const router = new HistoryAdaptedRouter(history);

function UuiEnhancedApp() {
  const { services } = useUuiServices({ router });
  Object.assign(svc, services);
  return (
    <UuiContext.Provider value={services}>
      <ErrorHandler>
        <App />
        <Notifications />
        <Modals />
        <DragGhost />
      </ErrorHandler>
    </UuiContext.Provider>
  );
}

const RoutedApp = withAuth(() => {
  return (
    <Router history={history}>
      <Provider store={store}>
        <UuiEnhancedApp />
      </Provider>
    </Router>
  );
});

function initApp() {
  const root = createRoot(window.document.getElementById("root") as Element);
  root.render(
    <StrictMode>
      <RoutedApp />
    </StrictMode>,
  );
}

initApp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
