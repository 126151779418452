import { produce } from "immer";
import { Reducer } from "redux";

import { INews } from "SP/news/news.types";
import { INewsActionTypes, NewsActionTypes } from "Store/actions/news.actions";

export interface INewsState {
  newsList: INews[];
  loading: boolean;
  singleNewsLoading: boolean;
  error: string | null;
  pagination: {
    currentPage: number;
    pageLinks: { [pageId: string]: number };
    isLastPage: boolean;
  };
}

const initialState: INewsState = {
  newsList: [],
  loading: false,
  singleNewsLoading: false,
  error: null,
  pagination: {
    currentPage: 1,
    pageLinks: {},
    isLastPage: true,
  },
};

const setNextPage = (draft: INewsState) => {
  if (draft.newsList.length) {
    draft.pagination.pageLinks[draft.pagination.currentPage + 1] = draft.newsList[draft.newsList.length - 1].id;
  }
};

const reportsReducer: Reducer<INewsState, INewsActionTypes> = produce((draft, action) => {
  switch (action.type) {
    case NewsActionTypes.GET_NEWS_LIST_REQUEST:
      draft.loading = true;
      break;
    case NewsActionTypes.GET_NEWS_LIST_SUCCESS:
      draft.loading = false;
      draft.newsList = action.news;
      draft.pagination.isLastPage = action.isLastPage;
      draft.pagination.currentPage = action.page;
      setNextPage(draft);
      break;
    case NewsActionTypes.GET_NEWS_LIST_FAILURE:
      draft.loading = false;
      draft.error = action.error;
      break;

    case NewsActionTypes.ADD_NEWS_REQUEST:
    case NewsActionTypes.EDIT_NEWS_REQUEST:
    case NewsActionTypes.DELETE_NEWS_REQUEST:
      draft.singleNewsLoading = true;
      break;

    case NewsActionTypes.ADD_NEWS_SUCCESS:
    case NewsActionTypes.EDIT_NEWS_SUCCESS:
    case NewsActionTypes.DELETE_NEWS_SUCCESS:
      draft.singleNewsLoading = false;
      break;

    case NewsActionTypes.ADD_NEWS_FAILURE:
    case NewsActionTypes.EDIT_NEWS_FAILURE:
    case NewsActionTypes.DELETE_NEWS_FAILURE:
      draft.singleNewsLoading = false;
      draft.error = action.error;
      break;
  }
}, initialState);

export default reportsReducer;
