import { produce } from "immer";
import { Reducer } from "redux";

import { ReportActionTypes } from "Pages/report/report.actions";
import { IFavorite } from "SP/favorites/favorites.types";
import { FavoritesActionTypes, IFavoritesActionTypes } from "Store/actions/favorites.actions";

export interface IFavoriteState {
  favorites: IFavorite[];
  isFavoritesLoaded: boolean;
  favoriteToggleLoading: {
    [key: string]: boolean;
  } | null;
  loading: boolean;
  error: string;
}

const initialState: IFavoriteState = {
  favorites: [],
  isFavoritesLoaded: false,
  loading: false,
  favoriteToggleLoading: null,
  error: null,
};

const handleFavoriteToggleLoading = (draft: IFavoriteState, action, loading): void => {
  draft.favoriteToggleLoading = {
    ...draft.favoriteToggleLoading,
    [action.reportId]: loading,
  };
};

const handleRemoveReportFromFavorites = (draft: IFavoriteState, reportId: number): void => {
  const unfavoriteIndex = draft.favorites.findIndex((f) => f.reportId === reportId);

  if (unfavoriteIndex !== -1) {
    draft.favorites.splice(unfavoriteIndex, 1);
  }
};

const favoritesReducer: Reducer<IFavoriteState, IFavoritesActionTypes> = produce((draft, action) => {
  switch (action.type) {
    case FavoritesActionTypes.GET_CURRENT_USER_FAVORITES_REQUEST:
      draft.loading = true;
      break;
    case FavoritesActionTypes.GET_CURRENT_USER_FAVORITES_SUCCESS:
      draft.favorites = action.payload;
      draft.isFavoritesLoaded = true;
      draft.loading = false;
      break;
    case FavoritesActionTypes.GET_CURRENT_USER_FAVORITES_FAILURE:
      draft.error = action.error;
      draft.isFavoritesLoaded = true;
      draft.loading = false;
      break;

    case FavoritesActionTypes.FAVORITE_REPORT_REQUEST:
    case FavoritesActionTypes.UNFAVORITE_REPORT_REQUEST:
      handleFavoriteToggleLoading(draft, action, true);
      break;

    case FavoritesActionTypes.FAVORITE_REPORT_SUCCESS:
      draft.favorites.unshift(action.favoriteItem);
      handleFavoriteToggleLoading(draft, action, false);
      break;

    case FavoritesActionTypes.UNFAVORITE_REPORT_SUCCESS:
      handleRemoveReportFromFavorites(draft, action.reportId);
      handleFavoriteToggleLoading(draft, action, false);
      break;

    case FavoritesActionTypes.FAVORITE_REPORT_FAILURE:
    case FavoritesActionTypes.UNFAVORITE_REPORT_FAILURE:
      handleFavoriteToggleLoading(draft, action, false);
      break;

    case ReportActionTypes.DELETE_REPORT_SUCCESS:
      handleRemoveReportFromFavorites(draft, action.reportId);
      break;
  }
}, initialState);

export default favoritesReducer;
