import React, { useCallback, useEffect } from "react";

import { svc } from "@App/services";
import { ReactComponent as addIcon } from "@epam/assets/icons/common/action-add-24.svg";
import { FlexCell, FlexRow, IconButton, Panel } from "@epam/loveship";

import { Layout } from "Components/layout";
import { useOpenVideoGuideByQuery } from "Components/video-guide-modal/useOpenVideoGuideByQuery";
import withNaming from "Helpers/bemClassname";
import { useUsersData } from "Hooks/index";
import { usePrimaryRoleModal } from "Hooks/usePrimaryRoleModal";
import type { INewsSavePayload } from "SP/news/news.types";

import { useDashboardReportsMethods } from "./dashboard.hooks";
import DashboardDomainSearch from "./dashboard-domain-search";
import DashboardFavoritesBlock from "./dashboard-favorites-block";
import DashboardNewsFeed from "./dashboard-news-feed";
import DashboardNewsModal, { ModalModes } from "./dashboard-news-modal";
import { RolesSectionList } from "./roles-section-list";
import { TagsProvider } from "./tags-context";

import "./dashboard.scss";

const cn = withNaming("dashboard");

const Dashboard: React.FC = () => {
  const { currentUser } = useUsersData();
  const { getDashboardReports } = useDashboardReportsMethods();

  useEffect(() => {
    getDashboardReports();
  }, []);

  useOpenVideoGuideByQuery();
  usePrimaryRoleModal();

  const openNewsModal = useCallback(
    ({ defaultMode, newsId, details }: { defaultMode?: ModalModes; newsId?: number; details?: INewsSavePayload }) => {
      svc.uuiModals
        .show((modalProps) => (
          <DashboardNewsModal
            defaultMode={defaultMode}
            newsId={newsId}
            modalProps={modalProps}
            currentUserPermissions={currentUser?.permissions}
            details={details}
          />
        ))
        .catch((e) => console.error(e));
    },
    [currentUser],
  );

  return (
    <Layout withWall>
      <TagsProvider>
        <FlexRow cx={cn("search-row")}>
          <FlexCell cx={cn("cell")} grow={1}>
            <DashboardDomainSearch />
          </FlexCell>
        </FlexRow>
        <FlexRow alignItems="top" cx={cn()}>
          <FlexCell cx={cn("cell")} grow={1}>
            <main className={cn("main")}>
              <DashboardFavoritesBlock />
              <RolesSectionList />
            </main>
          </FlexCell>
          <FlexCell cx={cn("cell")} width={339}>
            <aside className={cn("sidebar")}>
              <Panel shadow background="white" cx={cn("sidebar-panel")}>
                <div className="flex justify-between items-center">
                  <div className={cn("sidebar-title")}>The Latest News</div>
                  {currentUser?.permissions.canAddNews && (
                    <IconButton
                      cx={cn("sidebar-add-icon")}
                      color="sky"
                      icon={addIcon}
                      onClick={() => openNewsModal({ defaultMode: ModalModes.FORM_MODE })}
                    />
                  )}
                </div>
                <DashboardNewsFeed onOpenNewsModal={openNewsModal} />
              </Panel>
            </aside>
          </FlexCell>
        </FlexRow>
      </TagsProvider>
    </Layout>
  );
};

export default Dashboard;
