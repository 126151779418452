import { useField } from "formik";
import { ReactNode, useCallback, useState } from "react";
import { useDebouncedCallback } from "use-debounce";

import { LabeledInput, Text, TextArea, TextInput } from "@epam/loveship";

import { htmlDecode } from "Helpers/utils";

import { IFieldCommonProps } from "./field.interface";

interface IInputFieldProps extends IFieldCommonProps {
  type?: "number" | "text" | "textarea";
  maxLength?: number;
  rows?: number;
  handler?: (value: string) => string;
  charCounter?: boolean;
  label: string | ReactNode;
}

function getTrimValue(maxLength: number, newValue: string): string {
  if (!!maxLength && newValue.length > maxLength) {
    return newValue.substring(0, maxLength);
  }

  return newValue;
}

const InputField: React.FC<IInputFieldProps> = ({
  name,
  placeholder,
  type = "text",
  maxLength,
  rows,
  handler,
  label,
  charCounter,
}) => {
  const [field, meta, helpers] = useField(name);
  const [inputValue, setInputValue] = useState(field.value);

  const onTouch = useCallback(async () => {
    if (!meta.touched) {
      await helpers.setTouched(true);
    }
  }, [meta, helpers]);

  const debouncedOnChange = useDebouncedCallback(async (value) => {
    await helpers.setValue(value);
  }, 500);

  const error = meta.touched && !debouncedOnChange.pending() ? meta.error : "";

  const handleChange = useCallback(
    async (newValue) => {
      let trimValue = getTrimValue(maxLength, newValue);
      if (handler) {
        trimValue = handler(trimValue);
      }

      setInputValue(trimValue);
      await debouncedOnChange.callback(trimValue);
      await onTouch();
    },
    [handler, maxLength, onTouch, debouncedOnChange, handler, setInputValue],
  );

  return (
    <LabeledInput
      label={label}
      maxLength={maxLength}
      charCounter={charCounter}
      value={charCounter && htmlDecode(inputValue)}
    >
      {type === "textarea" ? (
        <TextArea
          placeholder={placeholder || "Please enter the value"}
          value={htmlDecode(inputValue)}
          onValueChange={handleChange}
          isInvalid={!!error}
          maxLength={maxLength}
          rows={rows}
        />
      ) : (
        <TextInput
          placeholder={placeholder || "Please enter the value"}
          type={type}
          value={inputValue}
          onValueChange={handleChange}
          isInvalid={!!error}
        />
      )}
      {!!error && (
        <Text color="fire" size="24">
          {error}
        </Text>
      )}
    </LabeledInput>
  );
};

export default InputField;
