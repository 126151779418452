import _cloneDeep from "lodash/cloneDeep";
import { FC, useMemo, useState } from "react";

import { DataTable, DataTableRow } from "@epam/loveship";
import { DataRowProps, DataSourceState, useArrayDataSource } from "@epam/uui-core";

import type { IReportVersion } from "SP/reports/reports.types";

import { cn, getVersionColumns, renderDiff } from "./version-history.helpers";

import "./version-history.scss";

interface IVersionHistoryProps {
  isMobile: boolean;
  versions: IReportVersion[];
}

export const VersionHistory: FC<IVersionHistoryProps> = ({ isMobile, versions }) => {
  const [value, onValueChange] = useState<DataSourceState<any, string>>({
    sorting: [{ field: "versionLabel", direction: "desc" }],
  });
  const isSortASC = value.sorting?.[0].direction === "asc";
  const sortedVersions = useMemo(() => (isSortASC ? _cloneDeep(versions).reverse() : versions), [isSortASC, versions]);

  const dataSource = useArrayDataSource<IReportVersion, string, unknown>(
    {
      items: versions,
      getId: (v) => v.versionLabel,
    },
    [versions],
  );

  const view = dataSource.useView(value, onValueChange, {
    sortBy: (item, sorting) => {
      if (sorting.field === "versionLabel") {
        return item.versionLabel;
      }
    },
  });

  const renderRow = (props: DataRowProps<IReportVersion, string>) => {
    const compareIndex = isSortASC ? props.index - 1 : props.index + 1;
    const isWithoutDiffRow = isSortASC ? props.index === 0 : versions.length === props.index + 1;

    if (isWithoutDiffRow) {
      return <DataTableRow borderBottom={false} {...props} key={props.rowKey} />;
    }

    return (
      <div data-testid="version-history-table-row" className={cn("table-row")} key={props.rowKey}>
        <DataTableRow borderBottom={false} {...props} key={props.rowKey} />
        {renderDiff(props.value, sortedVersions[compareIndex])}
      </div>
    );
  };

  return (
    <DataTable
      renderRow={renderRow}
      getRows={view.getVisibleRows}
      value={value}
      onValueChange={onValueChange}
      columns={getVersionColumns(isMobile)}
      headerTextCase="upper"
      border={false}
      {...view.getListProps()}
    />
  );
};
