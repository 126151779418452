import { useField } from "formik";
import _debounce from "lodash/debounce";
import _noop from "lodash/noop";
import React, { useCallback, useEffect, useRef } from "react";

import { DatePicker, FlexRow, LinkButton, Text } from "@epam/loveship";

import { DATE_PICKER_VALUE_FORMAT } from "Helpers/constants";
import dayjs from "Helpers/dayjsSetup";

import { IFieldCommonProps } from "./field.interface";

import "./datepicker-field.scss";

interface IDatePickerFieldProps extends IFieldCommonProps {
  filterCondition?: (day: dayjs.Dayjs) => boolean;
  isDisableInputChange?: boolean;
}

export const DEFAULT_DATE_FORMAT = "MMM DD, YYYY";

const DatePickerField: React.FC<IDatePickerFieldProps> = ({ name, isDisableInputChange, filterCondition }) => {
  const [field, meta, helpers] = useField(name);
  const datePickerRef = useRef<HTMLElement>(null);
  const error = meta.touched ? meta.error : "";

  const setTouched = useCallback(
    _debounce(() => {
      if (!meta.touched) {
        helpers.setTouched(true);
      }
    }, 500),
    [meta.touched, helpers.setTouched],
  );

  const handleChange = useCallback(
    (value: string) => {
      setTouched();
      helpers.setValue(value);
    },
    [setTouched, helpers.setValue],
  );

  const handleFilter = useCallback((day: dayjs.Dayjs) => filterCondition(day), [filterCondition]);

  useEffect(() => {
    if (isDisableInputChange) {
      const input = datePickerRef.current.querySelector("input");
      input.onbeforeinput = (event) => event.preventDefault();
    }
  }, [isDisableInputChange]);

  return (
    <>
      <DatePicker
        ref={datePickerRef}
        format={DEFAULT_DATE_FORMAT}
        isInvalid={!!error}
        value={field.value}
        onValueChange={handleChange}
        renderFooter={() => (
          <FlexRow size="36" cx="calendar-footer">
            <LinkButton
              size="36"
              caption="Today"
              onClick={() => handleChange(dayjs().format(DATE_PICKER_VALUE_FORMAT))}
            />
          </FlexRow>
        )}
        {...(filterCondition ? { filter: handleFilter } : {})}
      />
      {!!error && (
        <Text color="fire" size="24">
          {error}
        </Text>
      )}
    </>
  );
};

export default DatePickerField;
