import * as React from "react";

import { interactions, msalInstance } from "@App/libs/msal";
import { setRedirectRoute } from "@App/routes";
import { InteractionStatus } from "@azure/msal-browser";

import Loader from "Components/loader";
import { logStringifiedError } from "Helpers/utils";
import { PnPFetchClient } from "Libs/pnp";
import { configurePnP } from "SP/configure";

import { configureElasticAPM } from "../elasticAPM";

interface IState {
  hasError: boolean;
  errorMessage: string;
  authenticated: boolean;
}

export function withAuth<TOriginalProps>(
  WrappedComponent: React.FC<TOriginalProps>,
): React.ComponentClass<TOriginalProps> {
  return class Auth extends React.Component<TOriginalProps, IState> {
    private callbackId: string;

    constructor(props: TOriginalProps) {
      super(props);

      this.state = {
        hasError: false,
        errorMessage: null,
        authenticated: false,
      };
    }

    componentDidMount() {
      this.callbackId = msalInstance.addEventCallback((message) => {
        interactions.updateState(message, interactions.MsalProviderActionType.EVENT);
      });

      msalInstance.initialize().then(() => {
        this.handleLogin();
      });
    }

    componentWillUnmount(): void {
      if (this.callbackId) {
        msalInstance.removeEventCallback(this.callbackId);
      }
    }

    async handleLogin() {
      try {
        const tokenResponse = await msalInstance.handleRedirectPromise();

        if (!tokenResponse) {
          const accounts = msalInstance.getAllAccounts();
          if (accounts.length === 0) {
            setRedirectRoute();
            await msalInstance.loginRedirect();
          } else {
            this.handleAuthenticated();
          }
        } else {
          this.handleAuthenticated();
        }
      } catch (error: any) {
        logStringifiedError("Error when executing handleRedirectPromise. ", error);
        if (
          error.errorCode === "interaction_in_progress" &&
          interactions.getInteractionStatus() !== InteractionStatus.None
        ) {
          await PnPFetchClient.waitFor(() => interactions.getInteractionStatus() === InteractionStatus.None);
        }
        this.handleError(error);
      }

      interactions.updateState(null, interactions.MsalProviderActionType.UNBLOCK_INPROGRESS);
    }

    handleAuthenticated() {
      this.setState({
        authenticated: true,
      });
      configurePnP();
      configureElasticAPM();
    }

    handleError(error) {
      this.setState({
        hasError: true,
        errorMessage: error.statusText ? error.statusText.toString() : "Failed to authenticate the current user.",
      });
    }

    render(): JSX.Element {
      if (this.state.authenticated) {
        return <WrappedComponent {...this.props} />;
      }

      if (this.state.hasError) {
        return (
          <div className="app-container flex items-center justify-center">
            <code>{this.state.errorMessage}</code>
          </div>
        );
      }

      return <Loader />;
    }
  };
}
