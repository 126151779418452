import { useSelector } from "react-redux";

import { setSearchText } from "Pages/reports-browser/reports-filters.actions";
import { IRootReducerState } from "Store/reducers";
import { useAppDispatch } from "Store/store";

export const useGlobalSearchData = () => {
  const searchText = useSelector<IRootReducerState, string>((state) => state.filters.search);

  return {
    searchText,
    isSearchTextExist: !!searchText,
  };
};

export const useGlobalSearch = () => {
  const { searchText } = useGlobalSearchData();
  const dispatch = useAppDispatch();

  const setGlobalSearchText = (search: string) => {
    if (searchText !== search && (searchText || search)) {
      dispatch(setSearchText(search));
    }
  };

  return {
    searchText,
    setGlobalSearchText,
  };
};
