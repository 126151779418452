import { Store } from "redux";

import urlImg401 from "@App/assets/401.svg";
import { elasticAPM } from "@App/elasticAPM";
import { svc } from "@App/services";
import { FlexRow, LinkButton, Text } from "@epam/loveship";
import { UuiError } from "@epam/uui-core";

import { throwGlobalError } from "Store/actions/global.actions";
import { INotificationObject, notificationTypes, showNotification } from "Store/actions/notifications.actions";

import className from "./bemClassname";

import "SCSS/error-page.scss";

const cn = className("error-page");

export enum ErrorPageText {
  title_400 = "Something went wrong",
  subtitle_400 = "Sorry, this item does not exist.",
  title_401 = "Not authenticated",
  subtitle_401 = "Sorry but it seems like we can’t let you pass.",
  title_403 = "You have no permission",
  subtitle_403 = "Sorry but it seems like we can’t let you pass. ",
  title_404 = "Oooops! We couldn’t find this page",
  subtitle_404 = "It could have been deleted or never have existed.",
  title_500 = "500 Error! Something went wrong",
  subtitle_500 = "Looks like we are having some server issues. We'll get it fixed as soon as possible. Please try again later.",
  expireTokenTitle = "Sorry, we lost connection with server. Please try to reload the page.",
}

// TODO Find more appropriate way to handle this case
export const handleReloadPageWithTimeout = () => setTimeout(() => window.location.reload(), 0);

const renderTitle = (text: ErrorPageText) => (
  <Text fontSize="24" lineHeight="30" fontWeight="600" size="48" cx={cn("title")}>
    {text}
  </Text>
);

const renderSubTitle = (text: ErrorPageText, homePage?: boolean) => (
  <>
    <Text fontSize="18" lineHeight="24" cx={cn("subtitle")}>
      {text}
    </Text>

    {homePage && (
      <FlexRow cx={cn("subtitle-row")}>
        <Text cx={cn("subtitle")}>But you always can go to</Text>
        <LinkButton
          cx={cn("link", ["unstyled-link"])}
          caption="the home page"
          onClick={handleReloadPageWithTimeout}
          link={{
            pathname: "/",
          }}
        />
        .
      </FlexRow>
    )}
  </>
);

const errorTypes = {
  400: {
    status: 400,
    title: renderTitle(ErrorPageText.title_400),
    subtitle: renderSubTitle(ErrorPageText.subtitle_400, true),
  },
  401: {
    status: 401,
    title: renderTitle(ErrorPageText.title_401),
    subtitle: renderSubTitle(ErrorPageText.subtitle_401, true),
    imageUrl: urlImg401,
  },
  403: {
    status: 403,
    title: renderTitle(ErrorPageText.title_403),
    subtitle: renderSubTitle(ErrorPageText.subtitle_403, true),
  },
  404: {
    status: 404,
    title: renderTitle(ErrorPageText.title_404),
    subtitle: renderSubTitle(ErrorPageText.subtitle_404, true),
  },
  500: {
    status: 500,
    title: renderTitle(ErrorPageText.title_500),
    subtitle: renderSubTitle(ErrorPageText.subtitle_500),
  },
};

export const handleError = (err, store: Store): void => {
  if (process.env.UNIT_TEST === undefined) {
    elasticAPM.captureError(err);
  }

  const showErrorPage = (err) => svc.uuiErrors.reportError(new UuiError(err));

  if (errorTypes[err.status]) {
    store.dispatch(throwGlobalError() as any);
    showErrorPage(errorTypes[err.status]);
    return;
  }

  const notification: INotificationObject = {
    type: notificationTypes.error,
    text: "Error: Something went wrong",
    duration: 5,
  };

  store.dispatch(showNotification(notification) as any);
};
