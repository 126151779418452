import React, { useMemo } from "react";

import { ReactComponent as certificateIcon } from "@App/assets/warning_fill.svg";
import { IconButton, Tooltip } from "@epam/loveship";

import { BadgeColors } from "Components/badge";
import Badges from "Components/badges";
import FavoriteButton from "Components/favorite-button";
import { Owners } from "Components/owners/owners";
import { ReportButtons } from "Components/report-buttons/report-buttons";
import ReportLink from "Components/report-link";
import type { IMeasureElement } from "Components/virtual-list";
import dayjs from "Helpers/dayjsSetup";
import highlight from "Helpers/highlight";
import { htmlDecode, stripTags } from "Helpers/utils";
import { useTagsData } from "Hooks/index";
import { useGlobalSearchData } from "Hooks/useGlobalSearch";
import { handleRoles } from "Pages/report/report-details/report-details-helpers";
import { CERTIFIED_MARK_TOOLTIP } from "SP/reports/reports.constants";
import type { IReportExtended } from "SP/reports/reports.types";

const TableRow: React.FC<IReportExtended & IMeasureElement> = ({
  id,
  certified,
  reportName,
  modificationDate,
  description,
  ownersNames,
  tags,
  kbLink,
  videoGuideLink,
  reportLink,
  measureElement,
  primaryRoles,
}) => {
  const { searchText } = useGlobalSearchData();
  const { getReportTags } = useTagsData();
  const { applications, domains, roles, metrics } = getReportTags(tags);
  const decodedDescription = stripTags(htmlDecode(description));
  const rolesBadges = useMemo(() => handleRoles(roles, primaryRoles), [roles, primaryRoles]);

  return (
    <div ref={measureElement} className="report-table-row flex-wrap">
      <div className="report-table-column-favorite flex justify-center">
        <div className="cell-content flex flex-col justify-between items-center">
          <FavoriteButton className="star-icon" reportId={id} />
          {certified ? (
            <Tooltip placement="bottom" content={CERTIFIED_MARK_TOOLTIP}>
              <IconButton cx="certified-icon" icon={certificateIcon} color="sky" />
            </Tooltip>
          ) : null}
        </div>
      </div>
      <div className="report-table-column-name flex">
        <div className="cell-content flex items-start blue-text">
          <ReportLink
            id={id}
            size="24"
            className="report-link unstyled-link"
            link={reportLink}
            name={reportName}
            caption={highlight.peek(searchText, reportName, "reportNameDictionary")}
          />
          <ReportButtons reportId={id} reportName={reportName} kbLink={kbLink} videoGuideLink={videoGuideLink} />
        </div>
      </div>
      <div className="report-table-column-description">
        <div className="cell-content">
          <Tooltip cx="tooltip-whitespace-pre-line" content={decodedDescription} placement="auto">
            <span>{highlight.peek(searchText, decodedDescription, "descriptionDictionary")}</span>
          </Tooltip>
        </div>
      </div>
      <div className="report-table-column-owner">
        <div className="cell-content">
          <Owners ownersNames={ownersNames} reportName={reportName} spaceSeparated />
        </div>
      </div>
      <div className="report-table-column-role">
        <Badges groupName="Roles" className="cell-content" data={rolesBadges} searchText={searchText} />
      </div>
      <div className="report-table-column-domain">
        <Badges
          groupName="Business Domains"
          className="cell-content"
          color={BadgeColors.blue}
          data={domains}
          searchText={searchText}
        />
      </div>
      <div className="report-table-column-app">
        <Badges
          groupName="Apps"
          className="cell-content"
          color={BadgeColors.amber}
          data={applications}
          searchText={searchText}
        />
      </div>
      <div className="report-table-column-metrics">
        <Badges
          groupName="Metrics"
          className="cell-content"
          color={BadgeColors.grass}
          data={metrics}
          searchText={searchText}
        />
      </div>
      <div className="report-table-column-date">
        <div className="cell-content">
          <span>{modificationDate && dayjs.utc(modificationDate).format("MM/DD/YYYY")}</span>
        </div>
      </div>
    </div>
  );
};

export default TableRow;
