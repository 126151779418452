import { useMemo } from "react";
import { useSelector } from "react-redux";

import type { ISelectOption } from "Components/select/select.types";
import { mapUserForSelectOption } from "Helpers/userHandler";
import { IUser } from "SP/users/users.types";
import { checkEmailsRequest, searchUsersRequest } from "Store/actions/users.actions";
import type { IRootReducerState } from "Store/reducers";
import { useAppDispatch } from "Store/store";

export const useOwnerSelect = () => {
  const dispatch = useAppDispatch();

  const searchLoading = useSelector<IRootReducerState, boolean>((state) => state.users.loading);

  const allUsers = useSelector<IRootReducerState, IUser[]>((state) => state.users.allUsers);
  const userOptions: ISelectOption[] = useMemo(() => allUsers.map(mapUserForSelectOption), [allUsers]);

  const onSearchOptions = (request = "") => {
    dispatch(searchUsersRequest(request, 8));
  };

  const onCheckEmails = (emails: string[], callback: (userIds: number[]) => void) => {
    dispatch(checkEmailsRequest(emails, callback));
  };

  return { userOptions, searchLoading, onSearchOptions, onCheckEmails };
};
