import { useCallback } from "react";
import { useSelector } from "react-redux";

import { useQuery } from "Hooks/useQuery";
import { getReportVideoGuide } from "Pages/report/report.actions";
import type { IReportVideoGuide } from "SP/reports/reports.types";
import type { IRootReducerState } from "Store/reducers";
import { useAppDispatch } from "Store/store";

export const videoGuideQueryKey = "videoGuideReportId";

export function useReportVideoGuide() {
  const dispatch = useAppDispatch();

  const loading = useSelector<IRootReducerState, boolean>((state) => state.report.reportVideoGuideLoading);
  const reportVideoGuide = useSelector<IRootReducerState, IReportVideoGuide>((state) => state.report.reportVideoGuide);

  const handleGetReportVideoGuide = (id) => dispatch(getReportVideoGuide(id));

  return {
    loading,
    reportVideoGuide,
    getReportVideoGuide: handleGetReportVideoGuide,
  };
}

export const useSetVideoGuideQuery = () => {
  const { getQuery, setQuery } = useQuery();

  const setVideoGuideQuery = useCallback(
    (reportId: number) => {
      const oldQuery = getQuery();
      setQuery(oldQuery, { [videoGuideQueryKey]: reportId });
    },
    [getQuery, setQuery],
  );

  return { setVideoGuideQuery };
};
