import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Routes from "@App/routes";

import { getEncodedReportName } from "Helpers/reportsHelper";
import {
  getReportFileUrlRequest,
  getReportRequest,
  handleDeleteReport,
  resetSingleReport,
} from "Pages/report/report.actions";
import { IReportExtended, IReportPermissions } from "SP/reports/reports.types";
import { notificationTypes } from "Store/actions/notifications.actions";
import { IRootReducerState } from "Store/reducers";
import { useAppDispatch } from "Store/store";

import useNotifications from "./useNotifications";
import usePermissions from "./usePermissions";

export function useSingleReportData() {
  const error = useSelector<IRootReducerState, string>((state) => state.report.error);

  const report = useSelector<IRootReducerState, IReportExtended>((state) => state.report.savedReport);
  const loading = useSelector<IRootReducerState, boolean>((state) => state.report.loading);
  const permissions = useSelector<IRootReducerState, IReportPermissions>((state) => state.report.permissions);

  return {
    report,
    loading,
    error,
    permissions,
  };
}

export const useSingleReportOptions = (reportId: number, reportName: string) => {
  const { permissions, permissionsLoading, getReportPermissions } = usePermissions(reportId);

  const getDropdownOpenHandler = (callback) => () => {
    getReportPermissions();
    callback();
  };

  const history = useHistory();
  const { showNotification } = useNotifications();
  const { deleteReport } = useSingleReportMethods();

  const onEditReport = useCallback(() => {
    history.push(`${Routes.REPORTS_BROWSER.path}/${getEncodedReportName(reportName)}/edit`);
  }, [reportName]);

  const onDeleteReport = useCallback(() => {
    showNotification({
      type: notificationTypes.warning,
      text: "Are you sure you want to delete this report?",
      actions: (props) => [
        {
          name: "Delete",
          action: () => {
            deleteReport(reportId, props.onClose);
          },
        },
      ],
      duration: "forever",
      size: "large",
    });
  }, [showNotification, deleteReport]);

  return {
    onEditReport,
    onDeleteReport,
    permissions,
    permissionsLoading,
    getDropdownOpenHandler,
  };
};

export function useSingleReportMethods() {
  const dispatch = useAppDispatch();

  const getReport = (reportIdentifier: string) => dispatch(getReportRequest(reportIdentifier));
  const getReportFileUrl = (id) => dispatch(getReportFileUrlRequest(id));
  const resetReport = () => dispatch(resetSingleReport());
  const deleteReport = (id: number, callback: () => void) => dispatch(handleDeleteReport(id, callback));

  return {
    getReport,
    getReportFileUrl,
    resetReport,
    deleteReport,
  };
}
