import { ReactComponent as searchIcon } from "@epam/assets/icons/common/action-search-24.svg";
import { IconContainer } from "@epam/loveship";

import { VirtualList } from "Components/virtual-list";
import withNaming from "Helpers/bemClassname";
import { useReportsData, useReportsSort } from "Hooks/index";

import ReportCard from "./report-card";

const ReportCards = () => {
  const cn = withNaming("report-cards");
  const { isFilteredReportsLoaded, filteredReports } = useReportsData();
  const { sortedReports } = useReportsSort(filteredReports, true);
  const isReportsPresent = sortedReports.length > 0;

  return (
    <>
      {isReportsPresent && <VirtualList type="cards" items={sortedReports} estimateSize={188} ListItem={ReportCard} />}
      {isFilteredReportsLoaded && !isReportsPresent && (
        <div className={cn("not-found-content", ["flex flex-col items-center"])}>
          <IconContainer cx={cn("not-found-content-loop")} icon={searchIcon} />
          <span>No Reports Found</span>
        </div>
      )}
    </>
  );
};

export default ReportCards;
