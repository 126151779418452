import { ReactElement, useCallback, useRef, useState } from "react";
import { useDebouncedCallback } from "use-debounce";

import { ReactComponent as loadIcon } from "@App/assets/circle_loader_30 .svg";
import { IconContainer, PickerInput, Text } from "@epam/loveship";
import { ArrayDataSource } from "@epam/uui-core";

import withNaming from "Helpers/bemClassname";
import { isCanSelectPickerInput } from "Helpers/utils";

import type { ISelectOption, ISelectProps } from "./select.types";

import "./select.scss";

const getSearchElement = (portalRef: React.MutableRefObject<any>) => portalRef?.current?.querySelector("input");

export function Select<IType>({
  loading,
  options,
  placeholder,
  value,
  error,
  inputProps,
  renderCopyIcon,
  onValueChange,
  onSearchChange,
  renderNotFound,
  renderFooter,
  renderRow,
  renderTogglerItem,
  strictOnSearch,
}: ISelectProps<IType>): ReactElement {
  const cn = withNaming("select");
  const pickerInputRef = useRef<any>(null);
  const itemsDataSource = new ArrayDataSource({ items: options });
  const [search, setSearch] = useState("");
  const portalRef = useRef(null);

  const debouncedOnSearchChange = useDebouncedCallback((event) => {
    const newSearch = event.target.value;

    if (strictOnSearch) {
      onSearchChange?.(newSearch);
    } else if (search !== newSearch) {
      onSearchChange?.(newSearch);
      setSearch(newSearch);
    }
  }, 550);

  const handleGetSearchFields = useCallback((item: ISelectOption) => {
    const fields = [item.label];

    if (item.searchField) {
      return fields.concat(item.searchField);
    }

    return fields;
  }, []);

  const handleValueChange = useCallback(
    (newValue) => {
      if (isCanSelectPickerInput(value, newValue)) {
        onValueChange(newValue || []);
      }
    },
    [value, onValueChange],
  );

  const setOnSearch = useCallback(() => {
    const searchInput = getSearchElement(portalRef);

    if (searchInput && onSearchChange) {
      searchInput.oninput = debouncedOnSearchChange.callback;
    }
  }, [getSearchElement(portalRef), debouncedOnSearchChange, onSearchChange]);

  return (
    <>
      <div data-testid="select" className={cn("wrapper")}>
        {loading && (
          <IconContainer cx={cn("loader", { [`right-${inputProps?.size}`]: !!inputProps?.size })} icon={loadIcon} />
        )}
        {value?.length > 1 && renderCopyIcon?.({ cx: cn("clone-icon") })}
        <PickerInput<ISelectOption, IType>
          ref={pickerInputRef as any}
          valueType="id"
          selectionMode="multi"
          searchPosition="body"
          placeholder={placeholder || "Please select"}
          dataSource={itemsDataSource}
          value={value}
          onValueChange={handleValueChange}
          getSearchFields={handleGetSearchFields}
          getName={(item) => item.label}
          portalTarget={portalRef?.current}
          renderEmpty={renderNotFound}
          renderTag={renderTogglerItem}
          renderFooter={renderFooter}
          isInvalid={!!error}
          renderRow={renderRow}
          onFocus={setOnSearch}
          size={inputProps?.size}
          {...inputProps}
        />
        {!!error && (
          <Text color="fire" size="24">
            {error}
          </Text>
        )}
      </div>
      {onSearchChange && <div ref={portalRef}></div>}
    </>
  );
}
