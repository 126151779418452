import { FC, useEffect } from "react";

import { Button, Spinner } from "@epam/loveship";

import withNaming from "Helpers/bemClassname";
import { useMobileData } from "Hooks/useMobile";
import { useNewsData, useNewsPagination } from "Hooks/useNews";
import { INewsSavePayload } from "SP/news/news.types";

import { ReactComponent as chevronLeftIcon } from "../../../assets/navigation-chevron-left-24.svg";
import { ReactComponent as chevronRightIcon } from "../../../assets/navigation-chevron-right-24.svg";
import DashboardNewsFeedItem from "./dashboard-news-feed-item";

import "./dashboard-news-feed.scss";

interface IDashboardNewsFeedProps {
  onOpenNewsModal: (p: { newsId: number; details: INewsSavePayload }) => void;
}

const DashboardNewsFeed: FC<IDashboardNewsFeedProps> = ({ onOpenNewsModal }) => {
  const cn = withNaming("dashboard-news-feed");
  const { isMobile } = useMobileData();
  const newsLimit = isMobile ? 1 : 5;

  const { loading, newsList, getNewsList } = useNewsData();
  const { isFirstPage, isLastPage, loadPrevPage, loadNextPage } = useNewsPagination(newsLimit);

  const handleOpenNewsViewModal = (newsId: number, details: INewsSavePayload) => () => {
    onOpenNewsModal({ newsId, details });
  };

  useEffect(() => {
    getNewsList(newsLimit);
  }, []);

  return (
    <div className={cn()}>
      {loading && <Spinner cx={cn("spinner")} />}
      {newsList.map(({ newsTitle, description, reportLink, id, date }) => (
        <DashboardNewsFeedItem
          key={id}
          date={date}
          newsTitle={newsTitle}
          description={description}
          reportLink={reportLink}
          onOpenView={handleOpenNewsViewModal(id, {
            newsTitle,
            description,
            reportLink,
          })}
        />
      ))}
      <div className={cn("footer", ["flex"])}>
        <Button
          onIconClick={loadPrevPage}
          cx={cn("footer-chevron", { left: true })}
          icon={chevronLeftIcon}
          fill="solid"
          color="white"
          size="30"
          isDisabled={isFirstPage}
        />
        <Button
          onIconClick={loadNextPage}
          cx={cn("footer-chevron", { right: true })}
          icon={chevronRightIcon}
          color="white"
          size="30"
          isDisabled={isLastPage}
        />
      </div>
    </div>
  );
};

export default DashboardNewsFeed;
