import { useSelector } from "react-redux";

import { IReportPermissions } from "SP/reports/reports.types";
import { getPermissionsOnReport } from "Store/actions/permissions.actions";
import { IRootReducerState } from "Store/reducers";
import { useAppDispatch } from "Store/store";

const usePermissions = (reportId: number) => {
  const dispatch = useAppDispatch();

  const permissions = useSelector<IRootReducerState, IReportPermissions>(
    (state) => state.permissions.permissions[reportId],
  );
  const permissionsLoading = useSelector<IRootReducerState, boolean>(
    (state) => state.permissions.permissionsLoading[reportId],
  );

  const getReportPermissions = () => {
    dispatch(getPermissionsOnReport(reportId));
  };

  return {
    permissions,
    permissionsLoading,
    getReportPermissions,
  };
};

export default usePermissions;
