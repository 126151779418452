import React, { ForwardedRef, forwardRef, PropsWithChildren } from "react";

import withNaming from "Helpers/bemClassname";

import "./badge.scss";

export enum BadgeColors {
  "white" = "white",
  "amber" = "amber",
  "grass" = "grass",
  "blue" = "blue",
}

interface IBadgeProps {
  color?: BadgeColors;
  type?: string;
  className?: string;
  icon?: any;
  isGroupName?: boolean;
  onIconClick?: () => void;
  onClick?: () => void;
}

export const badgeCN = withNaming("badge");

const Badge: React.FC<PropsWithChildren<IBadgeProps>> = forwardRef(
  (
    { color, type, children, icon, className = "", isGroupName, onIconClick, onClick },
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const Icon = icon;

    return (
      <div
        className={badgeCN({ [color]: !!color, [type]: !!type, isGroupName }, [className])}
        ref={ref}
        onClick={onClick}
      >
        {children}
        {Icon && (
          <button className={badgeCN("button")} onClick={onIconClick}>
            <Icon />
          </button>
        )}
      </div>
    );
  },
);

export default Badge;
