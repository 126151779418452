import { useField } from "formik";
import _noop from "lodash/noop";
import React from "react";

import { ReactComponent as tuneIcon } from "@App/assets/tune.svg";
import { IconButton, Text, TextInput } from "@epam/loveship";

import withNaming from "Helpers/bemClassname";

import { IFieldCommonProps } from "./field.interface";

interface IUploadFileInputFieldProps extends IFieldCommonProps {
  onIconClick?: () => void;
}

export const cn = withNaming("upload-file-input");

const UploadFileInputField: React.FC<IUploadFileInputFieldProps> = ({ name, placeholder, onIconClick }) => {
  const [field, meta] = useField(name);
  const error = meta.touched ? meta.error : "";

  return (
    <div className={cn("wrapper")}>
      <TextInput
        isDisabled
        placeholder={placeholder || "Please enter the value"}
        type="text"
        value={field?.value?.destinationUrl}
        onValueChange={_noop}
        isInvalid={!!error}
        cx={!!error && cn("invalid")}
      />
      <IconButton cx={cn("icon-button")} icon={tuneIcon} onClick={onIconClick} />
      {!!error && (
        <Text color="fire" size="24" cx={cn("error-text")}>
          {error}
        </Text>
      )}
    </div>
  );
};

export default UploadFileInputField;
