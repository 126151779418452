import { produce } from "immer";
import { Reducer } from "redux";

import { GlobalActionsTypes, IGlobalActionsTypes } from "Store/actions/global.actions";

export interface IGlobalState {
  isGlobalErrorThrow: boolean;
  isMobile: boolean;
  isSynonymsWordsShow: boolean;
}

const initialState: IGlobalState = {
  isGlobalErrorThrow: false,
  isMobile: false,
  isSynonymsWordsShow: false,
};

const globalReducer: Reducer<IGlobalState, IGlobalActionsTypes> = produce((draft, action) => {
  switch (action.type) {
    case GlobalActionsTypes.THROW_GLOBAL_ERROR:
      draft.isGlobalErrorThrow = true;
      break;

    case GlobalActionsTypes.SET_IS_MOBILE:
      draft.isMobile = action.toggle;
      break;

    case GlobalActionsTypes.SET_IS_SYNONYM_WORDS_SHOW:
      draft.isSynonymsWordsShow = action.toggle;
      break;
  }
}, initialState);

export default globalReducer;
