import { useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";

import { getVideoGuideReportId, handleOpenVideoGuideModal } from "Components/video-guide-modal";
import { useUsersData } from "Hooks/useUsers";

export const useOpenVideoGuideByQuery = () => {
  const { currentUser } = useUsersData();

  const history = useHistory();
  const videoGuideReportId = useMemo(() => getVideoGuideReportId(history.location.search), [history.location.search]);

  useEffect(() => {
    if (videoGuideReportId && currentUser) {
      handleOpenVideoGuideModal(videoGuideReportId);
    }
  }, [videoGuideReportId, JSON.stringify(currentUser)]);
};
