import { FC } from "react";

import { ReactComponent as loaderIcon } from "@App/assets/circle_loader_30 .svg";
import { ReactComponent as favIcon } from "@epam/assets/icons/common/fav-rates-star-18.svg";
import { ReactComponent as favIconBorder } from "@epam/assets/icons/common/fav-rates-star-outline-24.svg";
import { IconButton, IconButtonProps, Tooltip } from "@epam/loveship";
import { DropdownPlacement } from "@epam/uui-core";

import { useFavorites, useMapStateToProps } from "Hooks/index";

interface IFavoriteButtonProps {
  reportId: number;
  className?: string;
  isTransparentIcon?: boolean;
  tooltipPlacement?: DropdownPlacement;
  defaultIconColor?: IconButtonProps["color"] | "night300";
}

const FavoriteButton: FC<IFavoriteButtonProps> = ({
  reportId,
  className,
  isTransparentIcon,
  tooltipPlacement = "bottom",
  defaultIconColor = "night300",
}) => {
  const { loading, favoriteItem } = useMapStateToProps((state) => ({
    loading: state.favorites.favoriteToggleLoading && state.favorites.favoriteToggleLoading[reportId],
    favoriteItem: state.favorites.favorites.find((f) => f.reportId === reportId),
  }));

  const { favoriteReport, unfavoriteReport } = useFavorites();

  const handleFavoriteToggle = () => {
    if (favoriteItem) {
      unfavoriteReport(reportId, favoriteItem.id);
    } else {
      favoriteReport(reportId);
    }
  };

  return (
    <Tooltip placement={tooltipPlacement} content={favoriteItem ? "Remove from My Favorite" : "Add to My Favorite"}>
      <IconButton
        cx={className}
        isDisabled={loading}
        icon={loading ? loaderIcon : isTransparentIcon && !favoriteItem ? favIconBorder : favIcon}
        color={favoriteItem ? "sun" : (defaultIconColor as any)}
        onClick={handleFavoriteToggle}
      />
    </Tooltip>
  );
};

export default FavoriteButton;
