import { produce } from "immer";
import { Reducer } from "redux";

import { IGroupedTags, ITagWithGroup } from "SP/tags/tags.types";
import { ITagsActionTypes, TagsActionTypes } from "Store/actions/tags.actions";

export interface ITagsState {
  groupedTags: IGroupedTags;
  allTags: ITagWithGroup[];
  loading: boolean;
  error: string;
}

const initialState: ITagsState = {
  groupedTags: null,
  allTags: [],
  loading: true,
  error: null,
};

const tagsReducer: Reducer<ITagsState, ITagsActionTypes> = produce((draft, action) => {
  switch (action.type) {
    case TagsActionTypes.GET_TAGS_REQUEST:
      draft.loading = true;
      break;
    case TagsActionTypes.GET_TAGS_SUCCESS:
      draft.groupedTags = action.groupedTags;
      draft.allTags = action.allTags;
      draft.loading = false;
      break;
    case TagsActionTypes.GET_TAGS_FAILURE:
      draft.loading = false;
      draft.error = action.error;
      break;
  }
}, initialState);

export default tagsReducer;
