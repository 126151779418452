import { Badge, Spinner } from "@epam/loveship";

import withNaming from "Helpers/bemClassname";

import "./filter-role-toggler.scss";

const cn = withNaming("filter-role-toggler");

interface IFilterRoleTogglerProps {
  label: string;
  loading: boolean;
  placeholder: string;
  selectionLength: number;
}

export const FilterRoleToggler = ({ selectionLength, placeholder, loading, label }: IFilterRoleTogglerProps) => {
  return (
    <div className={cn("")}>
      <div className={cn("body")}>
        {loading ? (
          <div className={cn("spinner-box")}>
            <Spinner cx={cn("spinner")} />
          </div>
        ) : (
          <>
            <div className={cn("text")}>{label || <span className={cn("placeholder")}>{placeholder}</span>}</div>
            {selectionLength > 1 && (
              <Badge cx={cn("badge")} caption={`+${selectionLength - 1}`} size="18" color="night100" fill="solid" />
            )}
          </>
        )}
      </div>
    </div>
  );
};
