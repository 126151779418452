import { FC, ReactNode } from "react";

import withNaming from "Helpers/bemClassname";

import "./layout.scss";

interface ILayoutProps {
  children: ReactNode;
  withWall?: boolean;
}

const cn = withNaming("app");

export const Layout: FC<ILayoutProps> = ({ children, withWall }) => {
  return (
    <div className={cn("")}>
      {withWall && (
        <div className={cn("wall", ["flex justify-start items-center"])}>
          <div className={cn("wall-content")}>
            <div className={cn("wall-text")}>Here you can find any report across Reports Digital Platform</div>
          </div>
        </div>
      )}
      <div className={cn("container")}>{children}</div>
    </div>
  );
};
