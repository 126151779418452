import { FC, useEffect } from "react";

import { svc } from "@App/services";
import { Button, FlexSpacer, Spinner } from "@epam/loveship";
import { IModal } from "@epam/uui-core";

import Modal from "Components/modal";
import { useMobileData } from "Hooks/useMobile";

import { VersionHistory } from "./version-history";
import { useVersionHistory } from "./version-history.hooks";

interface IVersionHistoryModalProps {
  reportId: number;
  modalProps: IModal<string>;
}

export const handleOpenVersionHistoryModal = async (reportId: number) => {
  return svc.uuiModals
    .show((modalProps) => <VersionHistoryModal modalProps={modalProps} reportId={reportId} />)
    .catch((e) => console.error(e));
};

export const VersionHistoryModal: FC<IVersionHistoryModalProps> = ({ reportId, modalProps }) => {
  const { isMobile } = useMobileData();
  const { loading, versions, getReportVersionHistory } = useVersionHistory();

  const handleClose = () => {
    modalProps.abort();
  };

  useEffect(() => {
    if (reportId) {
      getReportVersionHistory(reportId);
    }
  }, [reportId]);

  return (
    <Modal
      titleBorderBottom
      disallowClickOutside
      className="version-history-modal"
      onClose={handleClose}
      title="Version History"
      footer={
        <>
          <FlexSpacer />
          <Button cx="btn" fill="white" color="secondary" onClick={handleClose} caption="Close" />
        </>
      }
      modalProps={modalProps}
      width={isMobile ? 500 : 1000}
    >
      {loading && <Spinner />}
      {versions && <VersionHistory isMobile={isMobile} versions={versions} />}
    </Modal>
  );
};
