import { FC, forwardRef, memo } from "react";

import { useSortable } from "@dnd-kit/sortable";

import DragHandle from "Components/drag-handle";
import { getStyles, IDndItemProps } from "Helpers/dndHelper";
import type { IReport } from "SP/reports/reports.types";

import { DashboardCard } from "../dashboard-card";
import { cn as favoriteBlockCN } from "./dashboard-favorites-block";

interface ISortableFavoriteReportProps {
  report: IReport;
  disabled?: boolean;
  fullCard?: boolean;
}

export const FavoriteReport = memo(
  forwardRef<HTMLDivElement, IDndItemProps & ISortableFavoriteReportProps>(
    ({ active, dragOverlay, style, attributes, handleRef, listeners, report, disabled, fullCard }, ref) => {
      return (
        <div className={favoriteBlockCN("report", { active, dragOverlay })} style={style} {...attributes} ref={ref}>
          {!disabled && <DragHandle ref={handleRef} {...listeners} />}
          <DashboardCard {...report} fullCard={fullCard} />
        </div>
      );
    },
  ),
);

export const SortableFavoriteReport: FC<ISortableFavoriteReportProps> = ({ report, disabled }) => {
  const { attributes, active, listeners, setNodeRef, setActivatorNodeRef, transform, transition } = useSortable({
    id: report.id,
    disabled,
  });

  return (
    <FavoriteReport
      ref={setNodeRef}
      handleRef={setActivatorNodeRef}
      active={active?.id === report.id}
      attributes={attributes}
      listeners={listeners}
      style={getStyles(transform, transition)}
      report={report}
      disabled={disabled}
    />
  );
};
