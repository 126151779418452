import React, { useCallback, useState } from "react";

import { ReactComponent as CommunicationThumbUpIcon } from "@App/assets/thumb_up.svg";
import { ReactComponent as CommunicationThumbUpFillIcon } from "@App/assets/thumb_up-fill.svg";
import { Text } from "@epam/loveship";

import { cn, IRowsRef } from "./roles-field";

import "./roles-field.scss";

interface IRolesDropdownRowProps {
  label: string;
  id: string | number;
  isActive: boolean;
  valueRef: React.MutableRefObject<Set<unknown>>;
  handleChangePrimaryRoles: (id: string | number, isPrimaryChecked: boolean) => void;
  rowsRef: React.MutableRefObject<IRowsRef>;
}

export const RolesDropdownRow: React.FC<IRolesDropdownRowProps> = ({
  label,
  id,
  isActive,
  valueRef,
  handleChangePrimaryRoles,
  rowsRef,
}) => {
  const [isPrimaryChecked, setIsPrimaryChecked] = useState<boolean>(!!valueRef.current.has(id));
  rowsRef.current[id] = { setIsPrimaryChecked };

  const onClick = useCallback(
    (id: string | number) => {
      setIsPrimaryChecked((prev) => !prev);
      handleChangePrimaryRoles(id, isPrimaryChecked);
    },
    [isPrimaryChecked],
  );

  return (
    <div key={id} className={cn("dropdown-item", ["flex justify-between"])}>
      <Text fontSize="14" lineHeight="24" cx={cn("input-item-text")}>
        {label}
      </Text>
      <span
        onClick={(e) => {
          e.stopPropagation();
          isActive && onClick(id);
        }}
        className={cn("thumb-up", [!isActive && "not-active"])}
      >
        {isPrimaryChecked ? <CommunicationThumbUpFillIcon /> : <CommunicationThumbUpIcon />}
      </span>
    </div>
  );
};
