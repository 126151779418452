import _isEmpty from "lodash/isEmpty";
import React, { ReactElement, useLayoutEffect, useMemo, useRef, useState } from "react";

import { ReactComponent as LikeIcon } from "@epam/assets/icons/common/social-thumb-up-18.svg";

import Badge from "Components/badge";
import withNaming from "Helpers/bemClassname";
import highlight from "Helpers/highlight";
import { checkIsSomeTagHighlighted } from "Helpers/reportsHelper";

import type { IBadges } from "./badges.types";
import { BadgeDescriptionTooltip } from "./badges-description-tooltip";
import { BadgesGroup } from "./badges-group";

import "./badges.scss";

export const badgesCN = withNaming("badges");

const Badges: React.FC<IBadges> = ({
  color,
  data,
  className = "",
  groupName,
  contentHeight = 65,
  searchText,
  groupRender,
}) => {
  const [height, setHeight] = useState(0);
  const [simpleRender, setSimpleRender] = useState(true);
  const badgesRef = useRef(null);
  const newData = _isEmpty(data) ? [] : data;
  const isGroupTitleHighlighted = useMemo(
    () => checkIsSomeTagHighlighted(searchText, newData, "label"),
    [searchText, newData.length],
  );

  useLayoutEffect(() => {
    if (!simpleRender && newData.length > 0) {
      setSimpleRender(true);
    }
  }, [newData.length]);

  useLayoutEffect(() => {
    if (simpleRender) {
      setSimpleRender(false);
      setHeight(badgesRef.current.clientHeight);
    }
  }, [simpleRender]);

  const renderAllBadges = (): ReactElement[] => {
    return newData.map((tag) => (
      <BadgeDescriptionTooltip key={tag.id} description={tag.description}>
        <Badge color={color}>
          {tag.thumbUp && <LikeIcon className="thumb-up-icon" />}
          {highlight.peek(searchText, tag.label)}
        </Badge>
      </BadgeDescriptionTooltip>
    ));
  };

  const renderBadges = () => {
    const renderedAllBadges = renderAllBadges();

    if (groupRender || (!simpleRender && height > contentHeight)) {
      return (
        <BadgesGroup
          isHighlighted={isGroupTitleHighlighted}
          badges={renderedAllBadges}
          badgesCount={newData.length}
          groupName={groupName}
          color={color}
        />
      );
    }

    return renderedAllBadges;
  };

  return (
    <div className={badgesCN("", [className])} ref={badgesRef}>
      {renderBadges()}
    </div>
  );
};

export default Badges;
