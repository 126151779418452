import { useMemo } from "react";
import { useSelector } from "react-redux";

import { IGroupedTags, ITag, ITagWithGroup } from "SP/tags/tags.types";
import { getTags } from "Store/actions/tags.actions";
import { IRootReducerState } from "Store/reducers";
import { useAppDispatch } from "Store/store";

export type ITagSelectOption = {
  id: string;
  label: string;
  description: string;
};

const convertToSelectOptions = (tags: ITag[]): ITagSelectOption[] => {
  if (!tags) return [];

  return tags.map((tag) => ({
    id: tag.id,
    label: tag.name,
    description: tag.description,
  }));
};

const getTagsByGroup = (tagIds: string[], tags: ITagSelectOption[]) => {
  if (!tagIds) return [];
  return tags.filter((tag) => tagIds.includes(tag.id));
};

const getTagsFields = (tagIds: string[], tags: ITagSelectOption[], fieldName: keyof ITagSelectOption) => {
  return getTagsByGroup(tagIds, tags).map((tag) => tag[fieldName]);
};

export const useTagsData = () => {
  const groupedTags = useSelector<IRootReducerState, IGroupedTags>((state) => state.tags.groupedTags);
  const allTags = useSelector<IRootReducerState, ITagWithGroup[]>((state) => state.tags.allTags);
  const loading = useSelector<IRootReducerState, boolean>((state) => state.tags.loading);

  const { roles, domains, applications, metrics } = useMemo(
    () => ({
      roles: convertToSelectOptions(groupedTags && groupedTags.Role),
      domains: convertToSelectOptions(groupedTags && groupedTags.Domain),
      applications: convertToSelectOptions(groupedTags && groupedTags.Application),
      metrics: convertToSelectOptions(groupedTags && groupedTags.Metric),
    }),
    [groupedTags],
  );

  const getReportTags = (tagIds: string[]) => ({
    roles: getTagsByGroup(tagIds, roles),
    domains: getTagsByGroup(tagIds, domains),
    applications: getTagsByGroup(tagIds, applications),
    metrics: getTagsByGroup(tagIds, metrics),
  });

  const getReportTagIds = (tagIds: string[]) => ({
    roles: getTagsFields(tagIds, roles, "id"),
    domains: getTagsFields(tagIds, domains, "id"),
    applications: getTagsFields(tagIds, applications, "id"),
    metrics: getTagsFields(tagIds, metrics, "id"),
  });

  return {
    groupedTags: groupedTags || {},
    allTags,
    roles,
    domains,
    applications,
    metrics,
    getReportTags,
    getReportTagIds,
    loading,
  };
};

export const useTagsMethods = () => {
  const dispatch = useAppDispatch();
  const retrieveTags = () => {
    dispatch(getTags());
  };

  return {
    getTags: retrieveTags,
  };
};
