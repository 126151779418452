import { Formik } from "formik";
import _isEqual from "lodash/isEqual";
import { FC } from "react";

import { FlexCell, FlexRow } from "@epam/loveship";

import { InputField } from "Components/fields";
import { REPORT_LABELS } from "Pages/report/report-details/report-details-helpers";

import { IDashboardNewsFormProps } from "./dashboard-news-form.types";
import newsSchema from "./dashboard-news-form.validation";

import "./dashboard-news-form.scss";

const DashboardNewsForm: FC<IDashboardNewsFormProps> = ({
  formRef,
  isValidForm,
  initialValues,
  handleSubmit,
  onSetValidForm,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={newsSchema}
      innerRef={(ref) => (formRef.current = ref)}
      validate={(v) => {
        const isValid = newsSchema.isValidSync(v) && !_isEqual(initialValues, v);
        if (isValidForm !== isValid) {
          onSetValidForm(isValid);
        }
      }}
    >
      {() => (
        <div className="form dashboard-news-form">
          <FlexRow cx="form__row dashboard-news-form__row">
            <FlexCell grow={1}>
              <InputField label="News Title*" maxLength={100} name="newsTitle" placeholder="Enter News Title" />
            </FlexCell>
          </FlexRow>
          <FlexRow cx="form__row dashboard-news-form__row">
            <FlexCell grow={1}>
              <InputField
                label="Description*"
                maxLength={1000}
                name="description"
                placeholder="Enter Description"
                type="textarea"
                rows={3}
                charCounter={true}
              />
            </FlexCell>
          </FlexRow>
          <FlexRow cx="form__row dashboard-news-form__row">
            <FlexCell grow={1}>
              <InputField name="reportLink" placeholder="Enter value" label={REPORT_LABELS.linkToTheReport} />
            </FlexCell>
          </FlexRow>
        </div>
      )}
    </Formik>
  );
};

export default DashboardNewsForm;
