import { useDispatch } from "react-redux";
import createSagaMiddleware, { SagaMiddleware } from "redux-saga";

import rootSaga from "@App/sagas";
import { configureStore } from "@reduxjs/toolkit";

import { handleError } from "Helpers/errorHandler";

import { rootReducer } from "./reducers";

const sagaMiddleware: SagaMiddleware<object> = createSagaMiddleware({
  onError: (err: any) => handleError(err, store as any),
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(sagaMiddleware),
  devTools: process.env.NODE_ENV !== "production",
});

if (process.env.NODE_ENV !== "test") {
  sagaMiddleware.run(rootSaga);
}

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

export default store;
