import { cloneElement, FC, useCallback, useMemo, useRef, useState } from "react";

import { Tooltip as UuiTooltip } from "@epam/loveship";
import * as uui from "@epam/uui";
import { TooltipProps } from "@epam/uui-components";

import withNaming from "Helpers/bemClassname";
import useOutsideClick from "Hooks/useOutsideClick";

import "./tooltip.scss";

export interface ITooltipContentProps {
  onTooltipClose: (e) => void;
}

interface TooltipMods {
  color?: "white" | "fire" | "gray" | uui.TooltipProps["color"];
}

interface ITooltip extends TooltipProps, TooltipMods {
  content: (p: ITooltipContentProps) => React.ReactNode;
  trigger?: "click" | "hover";
}

const cn = withNaming("tooltip");

const TooltipContent = ({ toggleVisibility, content }) => {
  const tooltipContentRef = useRef();

  useOutsideClick(tooltipContentRef, toggleVisibility, true);

  return (
    <div className={cn("tooltip-content")} ref={tooltipContentRef}>
      {content({ onTooltipClose: toggleVisibility } as ITooltipContentProps)}
    </div>
  );
};

export const Tooltip: FC<ITooltip> = ({ children, content, placement, trigger, cx = "", color = "white" }) => {
  const isOnClickTrigger = useMemo(() => trigger === "click", [trigger]);
  const [value, setValue] = useState(isOnClickTrigger ? false : undefined);

  const toggleVisibility = useCallback(() => isOnClickTrigger && setValue((prev) => !prev), [value, isOnClickTrigger]);

  return (
    <UuiTooltip
      cx={cn("", [cx] as any)}
      value={value}
      content={<TooltipContent toggleVisibility={toggleVisibility} content={content} />}
      placement={placement}
      color={color}
    >
      {cloneElement(children as any, { onClick: toggleVisibility })}
    </UuiTooltip>
  );
};
