import { combineReducers } from "redux";

import dashboardReducer from "Pages/dashboard/dashboard.reducer";
import reportReducer from "Pages/report/report.reducer";
import reportsReducer from "Pages/reports-browser/reports-browser.reducer";
import reportsFilterReducer from "Pages/reports-browser/reports-filters.reducer";
import foldersReducer from "Store/reducers/folders.reducer";
import permissionsReducer from "Store/reducers/permissions.reducer";
import tagsReducer from "Store/reducers/tags.reducer";

import favoritesReducer from "./favorites.reducer";
import globalReducer from "./global.reducer";
import newsReducer from "./news.reducer";
import notificationsReducer from "./notifications.reducer";
import usersReducer from "./users.reducer";

export const rootReducer = combineReducers({
  global: globalReducer,
  reports: reportsReducer,
  folders: foldersReducer,
  filters: reportsFilterReducer,
  users: usersReducer,
  notifications: notificationsReducer,
  report: reportReducer,
  news: newsReducer,
  tags: tagsReducer,
  favorites: favoritesReducer,
  dashboard: dashboardReducer,
  permissions: permissionsReducer,
});

export type IRootReducerState = ReturnType<typeof rootReducer>;
