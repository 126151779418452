import _debounce from "lodash/debounce";
import { useEffect } from "react";
import { useSelector } from "react-redux";

import { setIsMobile } from "Store/actions/global.actions";
import { IRootReducerState } from "Store/reducers";
import { useAppDispatch } from "Store/store";

const MIN_WIDTH = 991;

export const useMobileData = () => {
  const isMobile = useSelector<IRootReducerState, boolean>((state) => state.global.isMobile);

  return { isMobile };
};

export const useMobileEffect = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const handler = _debounce(() => {
      dispatch(setIsMobile(window.innerWidth <= MIN_WIDTH));
    }, 300);

    window.addEventListener("resize", handler);
    // Call handler right away so state gets updated with initial window size
    handler();

    return () => {
      window.removeEventListener("resize", handler);
    };
  }, []);
};
