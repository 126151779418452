import { FC, ReactNode } from "react";

import { ReactComponent as chevronDown } from "@epam/assets/icons/common/navigation-chevron-down-18.svg";
import { ReactComponent as chevronRight } from "@epam/assets/icons/common/navigation-chevron-right-18.svg";
import { IconButton, Text } from "@epam/loveship";

import "./style.scss";

interface IExpandableMenuProps {
  type: string;
  menuText: string;
  onToggleCollapsed: () => void;
  isCollapsed: boolean;
  children: ReactNode;
}

// Don't render 'children' component unnecessarily
function isBooleanValue(isCollapsed) {
  return typeof isCollapsed === "boolean" ? isCollapsed : true;
}

const ExpandableMenu: FC<IExpandableMenuProps> = ({ type, menuText, children, isCollapsed, onToggleCollapsed }) => {
  const isCollapsedBoolean = isBooleanValue(isCollapsed);

  return (
    <div className={`expandable-menu flex flex-col ${type}`}>
      <div className="flex items-center cursor-pointer" onClick={onToggleCollapsed}>
        <IconButton cx="chevron-icon" icon={isCollapsedBoolean ? chevronRight : chevronDown} />
        <Text cx="capitalize" color="night600" size="24" fontSize="14">
          {menuText}
        </Text>
      </div>
      {isCollapsedBoolean ? null : <div className="expandable-menu__items flex flex-col">{children}</div>}
    </div>
  );
};

export default ExpandableMenu;
