import { useField } from "formik";
import React, { useCallback, useMemo, useRef } from "react";

import { DataPickerRow } from "@epam/loveship";
import { DataPickerRowProps } from "@epam/uui";

import { ISelectOption } from "Components/select/select.types";
import withNaming from "Helpers/bemClassname";
import { useTagsData } from "Hooks/useTags";
import { ReportFormFieldTypes } from "Pages/add-report/add-report-form";

import SelectField from "../select-field/select-field";
import { addDuplicatesToPrimary, sortPrimaryFirst } from "./helpers";
import { RolesDropdownRow } from "./roles-dropdown-row";
import { TogglerItem } from "./toggler-item";

import "./roles-field.scss";

export const cn = withNaming("roles-field");

export type IRowsRef = Record<string, { setIsPrimaryChecked: React.Dispatch<React.SetStateAction<boolean>> }>;

export const RolesField: React.FC = () => {
  const [primaryRolesField, , primaryRolesHelpers] = useField(ReportFormFieldTypes.REPORT_PRIMARY_ROLES);
  const [rolesField, , rolesHelpers] = useField(ReportFormFieldTypes.REPORT_ROLES);
  const { roles } = useTagsData();
  const valueRef = useRef(new Set(primaryRolesField.value));
  const rolesMap = useMemo(() => new Map(roles.map((i) => [i.id, i.label])), [roles]);
  const rowsRef = useRef<IRowsRef>({});

  const handleRemovePrimaryRole = useCallback(
    (newValue: string | number) => {
      valueRef.current.delete(newValue);
      primaryRolesHelpers.setValue([...valueRef.current]);
    },
    [primaryRolesHelpers],
  );

  const handleAddPrimaryRole = useCallback(
    (newValue: string | number) => {
      valueRef.current.add(newValue);
      primaryRolesHelpers.setValue([...valueRef.current]);
    },
    [primaryRolesHelpers],
  );

  const handleChangePrimaryRoles = useCallback(
    (id: string | number, isPrimaryChecked: boolean) => {
      isPrimaryChecked ? handleRemovePrimaryRole(id) : handleAddPrimaryRole(id);
    },
    [handleRemovePrimaryRole, handleAddPrimaryRole],
  );

  const onDeleteTogglerItem = useCallback(
    (e: any, roleId: string) => {
      e.stopPropagation();
      handleRemovePrimaryRole(roleId);
      rowsRef.current[roleId]?.setIsPrimaryChecked(false);
      rolesHelpers.setValue(rolesField.value.filter((id: string) => id !== roleId));
    },
    [rolesHelpers, rolesField, handleRemovePrimaryRole],
  );

  const onValueChange = useCallback(
    (newValue?: string[]) => {
      if (!newValue.length) {
        valueRef.current.clear();
        primaryRolesHelpers.setValue([]);
        Object.keys(rowsRef.current).forEach((id) => {
          rowsRef.current[id]?.setIsPrimaryChecked(false);
        });
      } else {
        valueRef.current.forEach((roleId) => {
          if (!newValue.find((id) => id === roleId)) {
            handleRemovePrimaryRole(roleId as string);
            rowsRef.current[roleId as string]?.setIsPrimaryChecked(false);
          }
        });
      }
    },
    [primaryRolesHelpers],
  );

  const renderTogglerItem = useCallback(
    (props) => {
      const isPrimary = valueRef.current.has(props.rowProps.value.id);
      return (
        <TogglerItem
          isPrimary={isPrimary}
          label={props.rowProps.value.label}
          id={props.rowProps.value.id}
          onDeleteTogglerItem={onDeleteTogglerItem}
          key={props.rowProps.value.id}
        />
      );
    },
    [onDeleteTogglerItem],
  );

  const renderRow = useCallback(
    (props: DataPickerRowProps<ISelectOption, any>) => {
      return (
        <DataPickerRow
          renderItem={() => (
            <RolesDropdownRow
              key={props.value.id}
              valueRef={valueRef}
              isActive={props.isChecked}
              label={props.value.label}
              id={props.value.id}
              handleChangePrimaryRoles={handleChangePrimaryRoles}
              rowsRef={rowsRef}
            />
          )}
          alignItems="center"
          padding="12"
          {...props}
        />
      );
    },
    [handleChangePrimaryRoles],
  );

  const getSortedTogglerValue = useCallback(
    (rolesIds: string[]) => sortPrimaryFirst(rolesIds, primaryRolesField.value, rolesMap),
    [primaryRolesField, rolesMap],
  );

  const copyPrimaryRoles = useCallback(() => [...valueRef.current] as string[], []);

  const setPrimaryRolesOnSearchChange = useCallback(
    (searchValue: string) => addDuplicatesToPrimary(searchValue, roles, handleAddPrimaryRole),
    [handleAddPrimaryRole, roles],
  );

  return (
    <SelectField
      withCopy
      name="roles"
      options={roles}
      placeholder="Select Roles"
      renderRow={renderRow}
      renderTogglerItem={renderTogglerItem}
      getSortedTogglerValue={getSortedTogglerValue}
      onValueChange={onValueChange}
      addOnCopy={copyPrimaryRoles}
      onSearchChange={setPrimaryRolesOnSearchChange}
      strictOnSearch
    />
  );
};
