import { FC, Fragment, ReactElement, ReactNode } from "react";

import { ModalBlocker, ModalFooter, ModalHeader, ModalWindow, Panel, ScrollBars } from "@epam/loveship";
import { cx, IModal } from "@epam/uui-core";

import "./modal.scss";

export interface IModalProps {
  footer: ReactElement;
  title: string;
  modalProps: IModal<string>;
  onClose?: () => void;
  width?: number;
  scrollableContent?: boolean;
  disallowClickOutside?: boolean;
  titleBorderBottom?: boolean;
  className?: string;
  footerBorderTop?: boolean;
  children?: ReactNode;
}

const Modal: FC<IModalProps> = ({
  width = 600,
  disallowClickOutside,
  footer,
  children,
  title,
  modalProps,
  scrollableContent,
  className,
  titleBorderBottom,
  onClose,
  footerBorderTop = true,
}) => {
  const ContentWrapper = scrollableContent ? ScrollBars : Fragment;
  const handleClose = () => {
    onClose?.();
    modalProps.abort();
  };

  return (
    <ModalBlocker
      {...modalProps}
      key={modalProps.key}
      disallowClickOutside={disallowClickOutside}
      cx={cx("modal", {
        [className]: !!className,
      })}
      zIndex={200}
      abort={handleClose}
    >
      <ModalWindow width={width}>
        <ModalHeader
          cx={cx("modal__title", {
            [`${className}__title`]: !!className,
          })}
          borderBottom={titleBorderBottom}
          title={title}
          onClose={handleClose}
        />
        <ContentWrapper>
          <Panel
            cx={cx("modal__content", {
              [`${className}__content`]: !!className,
            })}
          >
            {children}
          </Panel>
        </ContentWrapper>
        <ModalFooter borderTop={footerBorderTop}>{footer}</ModalFooter>
      </ModalWindow>
    </ModalBlocker>
  );
};

export default Modal;
