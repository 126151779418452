import { INotification } from "@epam/uui-core";

export enum NotificationsActionTypes {
  SHOW_NOTIFICATION = "[NOTIFICATIONS] SHOW",
  DELETE_NOTIFICATION_FROM_STACK = "[NOTIFICATIONS] DELETE",
}

declare type notificationAction = {
  name: string;
  action: () => void;
};

export enum notificationTypes {
  error = "error",
  success = "success",
  warning = "warning",
}

export interface INotificationObject {
  type: notificationTypes;
  text: string;
  size?: "large";
  duration?: number | "forever";
  actions?: (props: INotification) => notificationAction[];
}

interface IShowNotificationAction {
  type: NotificationsActionTypes.SHOW_NOTIFICATION;
  notification: INotificationObject;
  id: string;
}

export function showNotification(notification: INotificationObject): IShowNotificationAction {
  return {
    type: NotificationsActionTypes.SHOW_NOTIFICATION,
    notification,
    id: new Date().toISOString(),
  };
}

interface IDeleteNotificationFromStackAction {
  type: NotificationsActionTypes.DELETE_NOTIFICATION_FROM_STACK;
  id: string;
}

export function deleteNotificationFromStack(id: string): IDeleteNotificationFromStackAction {
  return {
    type: NotificationsActionTypes.DELETE_NOTIFICATION_FROM_STACK,
    id,
  };
}

export type INotificationActionTypes = IShowNotificationAction | IDeleteNotificationFromStackAction;
