import { useSelector } from "react-redux";

import { IFolder } from "SP/folders/folders.types";
import { clearFolders, getRootFoldersRequest, getSubFoldersRequest } from "Store/actions/folders.actions";
import { IRootReducerState } from "Store/reducers";
import { useAppDispatch } from "Store/store";

export const useFoldersData = () => {
  const rootFolders = useSelector<IRootReducerState, IFolder[]>((state) => state.folders.rootFolders);
  const loading = useSelector<IRootReducerState, boolean>((state) => state.folders.loading);

  return { loading, rootFolders };
};

export const useFoldersMethods = () => {
  const dispatch = useAppDispatch();

  const onGetSubFolders = (id = "", callback) => {
    dispatch(getSubFoldersRequest(id, callback));
  };

  const onGetRootFolders = (callback) => {
    dispatch(getRootFoldersRequest(callback));
  };

  const onClearFolders = () => {
    dispatch(clearFolders());
  };

  return { onGetSubFolders, onGetRootFolders, onClearFolders };
};
