import React, { useCallback, useRef } from "react";

import { ReactComponent as loader } from "@App/assets/circle_loader_30 .svg";
import { ReactComponent as videoGuideIcon } from "@App/assets/video_guide.svg";
import Routes from "@App/routes";
import { ReactComponent as copyIcon } from "@epam/assets/icons/common/action-copy_content-18.svg";
import { ReactComponent as deleteIcon } from "@epam/assets/icons/common/action-delete-18.svg";
import { ReactComponent as editIcon } from "@epam/assets/icons/common/content-edit-18.svg";
import { ReactComponent as optionsIcon } from "@epam/assets/icons/common/navigation-more_vert-18.svg";
import { ReactComponent as copiedIcon } from "@epam/assets/icons/common/notification-done-18.svg";
import { Dropdown, IconButton } from "@epam/loveship";

import { useSetVideoGuideQuery } from "Components/video-guide-modal/video-guide-modal.hooks";
import withNaming from "Helpers/bemClassname";
import { getEncodedReportName } from "Helpers/reportsHelper";
import { useCopyText } from "Hooks/useCopyText";
import { useSingleReportOptions } from "Hooks/useSingleReport";
import { REPORT_LABELS } from "Pages/report/report-details/report-details-helpers";

import { ContextMenuButton } from "./context-menu-button";

export const DEFAULT_MENU_WIDTH = 110;

interface IReportContextMenuProps {
  reportId: number;
  reportName: string;
  kbLink?: string;
  videoGuideLink?: string;
  isShowDelete?: boolean;
  dropdownWidth?: number;
}

const cn = withNaming("report-context-menu-icon");

const ReportContextMenu: React.FC<IReportContextMenuProps> = ({
  reportId,
  reportName,
  videoGuideLink,
  isShowDelete = true,
  dropdownWidth = DEFAULT_MENU_WIDTH,
}) => {
  const { setVideoGuideQuery } = useSetVideoGuideQuery();
  const { onDeleteReport, onEditReport, getDropdownOpenHandler, permissionsLoading, permissions } =
    useSingleReportOptions(reportId, reportName);

  const copyRef = useRef(null);
  const { isCopied, handleCopyUrl } = useCopyText(copyRef);

  const handleCopy = useCallback(
    (e) => {
      handleCopyUrl(e, `${window.location.origin}${Routes.REPORTS_BROWSER.path}/${getEncodedReportName(reportName)}`);
    },
    [reportName, handleCopyUrl],
  );

  const handleVideoTutorialClick = useCallback(
    (onClose: () => void) => () => {
      setVideoGuideQuery(reportId);
      onClose();
    },
    [],
  );

  return (
    <Dropdown
      openOnClick
      placement="bottom-end"
      renderTarget={(props) => (
        <IconButton
          {...props}
          cx={cn("", { isOpen: props.isOpen, isLoading: permissionsLoading })}
          icon={permissionsLoading ? loader : optionsIcon}
          onClick={getDropdownOpenHandler(props.onClick)}
        />
      )}
      renderBody={({ onClose }) => (
        <div style={{ width: dropdownWidth, backgroundColor: "#FFFFFF" }}>
          {!!permissions && (
            <>
              {videoGuideLink && (
                <ContextMenuButton
                  onClick={handleVideoTutorialClick(onClose)}
                  icon={videoGuideIcon}
                  label={REPORT_LABELS.viewVideoTutorial}
                />
              )}
              <ContextMenuButton
                onClick={handleCopy}
                icon={isCopied ? copiedIcon : copyIcon}
                label={REPORT_LABELS.copyLink}
              />
              {permissions.canEdit && <ContextMenuButton onClick={onEditReport} icon={editIcon} label="Edit" />}
              {permissions.canDelete && isShowDelete && (
                <ContextMenuButton onClick={onDeleteReport} icon={deleteIcon} label="Delete" />
              )}
            </>
          )}
        </div>
      )}
    />
  );
};

export default ReportContextMenu;
