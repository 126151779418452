import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import withNaming from "Helpers/bemClassname";
import { getSearchSegments } from "Helpers/utils";
import { useQuery } from "Hooks/useQuery";
import { useSynonymWords } from "Hooks/useSynonymWords";
import SearchService from "SP/search/search.service";
import type { IRootReducerState } from "Store/reducers";

import "./synonym-words.scss";

export const SynonymWords = () => {
  const cn = withNaming("synonym-words");
  const isReportsLoaded = useSelector<IRootReducerState, boolean>((state) => state.reports.isFilteredReportsLoaded);
  const isReportsFound = useSelector<IRootReducerState, boolean>((state) => state.reports.filteredReports.length > 0);
  const [show, setShow] = useState(false);
  const { setIsSynonymWordsShow } = useSynonymWords();

  const { getQuery } = useQuery();
  const { search = "" } = getQuery();
  const { reportNameDictionary, descriptionDictionary } = SearchService.CachedDictionary;

  const renderWords = useMemo(() => {
    const words = getSearchSegments(search);
    const wordsSet = new Set(
      words
        .map((w) => reportNameDictionary[w] || descriptionDictionary[w] || [])
        .flat()
        .filter((w) => !words.includes(w)),
    );

    return [...wordsSet].join(", ");
  }, [search, reportNameDictionary, descriptionDictionary]);

  useEffect(() => {
    if (isReportsLoaded) {
      const words = getSearchSegments(search);
      const isShow =
        isReportsFound &&
        words.some((w) => reportNameDictionary.hasOwnProperty(w) || descriptionDictionary.hasOwnProperty(w));

      setIsSynonymWordsShow(isShow);
      setShow(isShow);
    }
  }, [isReportsLoaded, isReportsFound, search]);

  if (show) {
    return (
      <div className={cn("", ["flex items-center"])}>
        <div className={cn("suffix", ["p-0"])}>Synonym words:</div>
        <div className={cn("content", ["p-0 one-line"])} title={renderWords}>
          {renderWords}
        </div>
      </div>
    );
  }

  return null;
};
