import { object } from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

import { svc } from "@App/services";
import { ErrorNotification, Snackbar, SuccessNotification, Text, WarningNotification } from "@epam/loveship";
import { INotification } from "@epam/uui-core";

import withNaming from "Helpers/bemClassname";
import {
  deleteNotificationFromStack,
  INotificationObject,
  notificationTypes,
} from "Store/actions/notifications.actions";
import { IRootReducerState } from "Store/reducers";

interface INotificationsStateProps {
  notifications: {
    [id: string]: INotificationObject;
  };
  lastNotificationId: string | null;
}

interface INotificationsDispatchProps {
  deleteNotification: (id: string) => void;
}

type INotificationsProps = INotificationsStateProps & INotificationsDispatchProps;

class Notifications extends React.Component<INotificationsProps> {
  static contextTypes = {
    uuiNotifications: object,
  };

  createNotification = (
    notification: INotificationObject,
    notificationProps: INotification,
    type: notificationTypes,
  ) => {
    const { text, actions, size } = notification;
    const cn = withNaming("notification");

    const extendedNotificationProps = {
      ...notificationProps,
      cx: cn({ [size]: !!size }),
      ...(actions && { actions: actions(notificationProps) }),
    };

    const textElement = (
      <Text size="24" fontSize="14" cx={cn("text")}>
        {text}
      </Text>
    );

    switch (type) {
      case "error":
        return <ErrorNotification {...extendedNotificationProps}>{textElement}</ErrorNotification>;
      case "success":
        return <SuccessNotification {...extendedNotificationProps}>{textElement}</SuccessNotification>;
      case "warning":
        return <WarningNotification {...extendedNotificationProps}>{textElement}</WarningNotification>;
    }
  };

  showNotification = (notification: INotificationObject) => {
    svc.uuiNotifications
      .show((props: INotification) => this.createNotification(notification, props, notification.type), {
        position: "top-center",
        duration: notification.duration || 3,
      })
      .catch((e) => console.error(e));
  };

  componentDidUpdate(prevProps: INotificationsProps) {
    const lastNotificationId = this.props.lastNotificationId;
    if (lastNotificationId && lastNotificationId !== prevProps.lastNotificationId) {
      this.showNotification(this.props.notifications[lastNotificationId]);
      this.props.deleteNotification(lastNotificationId);
    }
  }

  render() {
    return <Snackbar />;
  }
}

const mapStateToProps = (state: IRootReducerState) => ({
  notifications: state.notifications.notifications,
  lastNotificationId: state.notifications.lastNotificationId,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      deleteNotification: deleteNotificationFromStack,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
