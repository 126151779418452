import { call, fork, ForkEffect, put, takeEvery } from "redux-saga/effects";

import FoldersService from "SP/folders";
import {
  FoldersActionsTypes,
  getRootFoldersFailure,
  getRootFoldersSuccess,
  getSubFoldersFailure,
  getSubFoldersSuccess,
  IGetRootFoldersRequestAction,
  IGetSubFoldersRequestAction,
} from "Store/actions/folders.actions";

const foldersService = new FoldersService();

export function* getRootFolders(action: IGetRootFoldersRequestAction) {
  try {
    const rootFolders = yield call([foldersService, "getRootFolders"]);
    yield put(getRootFoldersSuccess(rootFolders));

    if (action.callback) {
      action.callback(rootFolders);
    }
  } catch (e: any) {
    yield put(getRootFoldersFailure(e));
    throw e;
  }
}

export function* watchGetRootFolders() {
  yield takeEvery(FoldersActionsTypes.GET_ROOT_FOLDERS_REQUEST, getRootFolders);
}

export function* getSubFolders(action: IGetSubFoldersRequestAction) {
  try {
    const subFolders = yield call([foldersService, "getSubFolders"], action.parentFolderId);
    yield put(getSubFoldersSuccess(action.parentFolderId, subFolders));

    if (action.callback) {
      action.callback();
    }
  } catch (e: any) {
    yield put(getSubFoldersFailure(e));
    throw e;
  }
}

export function* watchGetSubFolders() {
  yield takeEvery(FoldersActionsTypes.GET_SUB_FOLDERS_REQUEST, getSubFolders);
}

export default function* foldersSagas(): Iterator<ForkEffect> {
  yield fork(watchGetRootFolders);
  yield fork(watchGetSubFolders);
}
