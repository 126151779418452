import _omit from "lodash/omit";
import qs from "qs";
import { FC, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";

import { svc } from "@App/services";
import { Button, FlexSpacer, Spinner } from "@epam/loveship";
import { IModal } from "@epam/uui-core";

import Modal from "Components/modal";
import withNaming from "Helpers/bemClassname";
import { useMobileData } from "Hooks/useMobile";
import { useQuery } from "Hooks/useQuery";
import { useUsersData } from "Hooks/useUsers";

import { useReportVideoGuide, videoGuideQueryKey } from "./video-guide-modal.hooks";

import "./video-guide-modal.scss";

interface IVideoGuideModalModalProps {
  reportId: number;
  modalProps: IModal<string>;
}

export const getVideoGuideReportId = (search: string): number => {
  const { [videoGuideQueryKey]: reportId } = qs.parse(search, { ignoreQueryPrefix: true });

  return +reportId;
};

export const handleOpenVideoGuideModal = async (reportId: number) => {
  return svc.uuiModals
    .show((modalProps) => <VideoGuideModal modalProps={modalProps} reportId={reportId} />)
    .catch((e) => console.error(e));
};

export const VideoGuideModal: FC<IVideoGuideModalModalProps> = ({ reportId, modalProps }) => {
  const cn = withNaming("video-guide-modal");
  const { getQuery, setQuery } = useQuery();
  const { isMobile } = useMobileData();
  const { loading, reportVideoGuide, getReportVideoGuide } = useReportVideoGuide();

  const handleClose = () => {
    const newQuery = _omit(getQuery(), videoGuideQueryKey);
    setQuery(null, newQuery);
    modalProps.abort();
  };

  useEffect(() => {
    if (reportId) {
      getReportVideoGuide(reportId);
    }
  }, [reportId]);

  return (
    <Modal
      titleBorderBottom
      disallowClickOutside
      className={cn()}
      onClose={handleClose}
      title={`Report Video Guide - ${reportVideoGuide?.reportName || ""}`}
      footer={
        <>
          <FlexSpacer />
          <Button cx="btn" fill="white" color="secondary" onClick={handleClose} caption="Close" />
        </>
      }
      modalProps={modalProps}
      width={isMobile ? 500 : 1000}
    >
      {loading ? (
        <Spinner />
      ) : (
        <iframe
          src={reportVideoGuide?.videoGuideLink}
          height="500"
          width={isMobile ? "500" : "1000"}
          allowFullScreen
        ></iframe>
      )}
    </Modal>
  );
};
