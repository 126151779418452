import { FC, useEffect, useMemo, useRef, useState } from "react";

import { ReactComponent as CommunicationThumbUpFillIcon } from "@epam/assets/icons/common/social-thumb-up-18.svg";

import Badge, { BadgeColors } from "Components/badge/badge";
import { IBadge } from "Pages/report/report-details/report-details-helpers";

import { badgesCN } from "./badges";
import { BadgeDescriptionTooltip } from "./badges-description-tooltip";
import { BadgesTooltip } from "./badges-tooltip";

import "./badges.scss";

interface IBadgesComplexGroupProps {
  badges: IBadge[];
  color?: BadgeColors;
  contentHeight: number;
}

const getBadgeItems = (badges: IBadge[], color?: BadgeColors) =>
  badges.map((badge) => (
    <BadgeDescriptionTooltip key={badge.id} description={badge.description}>
      <Badge color={color}>
        {badge.thumbUp && <CommunicationThumbUpFillIcon className="thumb-up-icon" />}
        {badge.label}
      </Badge>
    </BadgeDescriptionTooltip>
  ));

export const BadgesComplexGroup: FC<IBadgesComplexGroupProps> = ({ badges, color, contentHeight }) => {
  const badgesRef = useRef(null);
  const [visibleBadgesCount, setVisibleBadgesCount] = useState(badges.length);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      if (entries?.[0]?.target.clientHeight > contentHeight) {
        setVisibleBadgesCount((prev) => prev - 1);
      }
    });

    observer.observe(badgesRef.current);

    return () => observer.disconnect();
  }, [visibleBadgesCount]);

  const visibleBadges = useMemo(() => badges.slice(0, visibleBadgesCount), [visibleBadgesCount]);

  const hiddenBadges = useMemo(() => badges.slice(visibleBadgesCount), [visibleBadgesCount]);

  return (
    <div style={{ height: `${contentHeight}px` }} className={badgesCN("container")}>
      <div className={badgesCN("")} ref={badgesRef}>
        {getBadgeItems(visibleBadges, color)}
        {hiddenBadges.length ? (
          <BadgesTooltip badges={getBadgeItems(hiddenBadges, color)}>
            <Badge isGroupName className="cursor-pointer" color={color}>
              +{hiddenBadges.length}
            </Badge>
          </BadgesTooltip>
        ) : null}
      </div>
    </div>
  );
};
