import React from "react";

import { FlexRow, IconContainer, Text } from "@epam/loveship";

import "./context-menu-button.scss";

interface IContextMenuButtonProps {
  onClick: (e?: any) => void;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  label: string;
}

export const ContextMenuButton: React.FC<IContextMenuButtonProps> = ({ onClick, icon, label }) => (
  <FlexRow padding="12" background="white" onClick={onClick} cx="context-menu-btn">
    <IconContainer icon={icon} />
    <Text>{label}</Text>
  </FlexRow>
);
