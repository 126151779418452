import { FC } from "react";

import { FlexCell, FlexRow, LabeledInput, LinkButton, Text } from "@epam/loveship";

import withNaming from "Helpers/bemClassname";
import { handleShortLink } from "Helpers/utils";
import { INewsSavePayload } from "SP/news/news.types";

import "./dashboard-news-details.scss";

const DashboardNewsDetails: FC<INewsSavePayload> = ({ newsTitle, description, reportLink }) => {
  const cn = withNaming("dashboard-news-details");
  return (
    <div className={cn()}>
      <FlexRow cx={cn("row")}>
        <FlexCell grow={1}>
          <LabeledInput cx={cn("label")} label="News Title" labelPosition="top">
            {reportLink ? (
              <LinkButton cx="unstyled-link" target="_blank" caption={newsTitle} href={handleShortLink(reportLink)} />
            ) : (
              <Text cx={cn("text", ["p-0"])} fontSize="14" lineHeight="24">
                {newsTitle}
              </Text>
            )}
          </LabeledInput>
        </FlexCell>
      </FlexRow>
      <FlexRow cx={cn("row")}>
        <FlexCell grow={1}>
          <LabeledInput cx={cn("label")} label="Description" labelPosition="top">
            <Text cx={cn("text", { description: true }, ["p-0"])} fontSize="14" lineHeight="24">
              {description}
            </Text>
          </LabeledInput>
        </FlexCell>
      </FlexRow>
    </div>
  );
};

export default DashboardNewsDetails;
