import React from "react";

import { ReactComponent as CloseIcon } from "@App/assets/close-icon.svg";
import { ReactComponent as CommunicationThumbUpFillIcon } from "@App/assets/thumb_up-fill.svg";
import { Text } from "@epam/loveship";

import { cn } from "./roles-field";

import "./roles-field.scss";

interface ITogglerItemProps {
  isPrimary: boolean;
  label: string;
  id: string;
  onDeleteTogglerItem: (e: any, roleId: string) => void;
}

export const TogglerItem: React.FC<ITogglerItemProps> = ({ isPrimary, label, id, onDeleteTogglerItem }) => {
  return (
    <span className={cn("input-item", ["flex items-center"])}>
      {isPrimary && (
        <div className={cn("input-item-thumb-up")}>
          <CommunicationThumbUpFillIcon />
        </div>
      )}
      <Text fontSize="12" lineHeight="24" cx={cn("input-item-text")}>
        {label}
      </Text>
      <div className={cn("input-item-remove")} onClick={(e) => onDeleteTogglerItem(e, id)}>
        <CloseIcon />
      </div>
    </span>
  );
};
