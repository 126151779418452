import React, { useMemo } from "react";

import { dashboardDefaultRole } from "Helpers/utils";
import { getSpecificRoleNamesOrder, useDashboardReportsSort } from "Hooks/useReportsSort";

import { useDashboardReportsData } from "../dashboard.hooks";
import { DashboardSection } from "../dashboard-section/dashboard-section";
import { useTagsContext } from "../tags-context";

export const RolesSectionList: React.FC = () => {
  const { loading, roles } = useTagsContext();
  const { roleRelatedReports } = useDashboardReportsData();
  const { sortedReports: sortedRoleRelatedReports } = useDashboardReportsSort(roleRelatedReports);

  const sortedRoles = useMemo(() => {
    return roles
      ? [dashboardDefaultRole.label, ...roles.map((role) => role.label)].sort(getSpecificRoleNamesOrder)
      : [];
  }, [roles]);

  return (
    <>
      {sortedRoles.map(
        (filterRole) =>
          roleRelatedReports[filterRole] && (
            <DashboardSection
              key={`role-section-${filterRole}`}
              loading={loading}
              reports={sortedRoleRelatedReports[filterRole]}
              title={filterRole}
              allReportsFilter={`Role=${filterRole}`}
            />
          ),
      )}
    </>
  );
};
