import { produce } from "immer";
import { Reducer } from "redux";

import {
  INotificationActionTypes,
  INotificationObject,
  NotificationsActionTypes,
} from "../actions/notifications.actions";

export interface INotificationsState {
  notifications: {
    [id: string]: INotificationObject;
  };
  lastNotificationId: string | null;
}

const initialState: INotificationsState = {
  notifications: {},
  lastNotificationId: null,
};

const notificationsReducer: Reducer<INotificationsState, INotificationActionTypes> = produce((draft, action) => {
  switch (action.type) {
    case NotificationsActionTypes.SHOW_NOTIFICATION:
      draft.notifications[action.id] = action.notification;
      draft.lastNotificationId = action.id;
      break;
    case NotificationsActionTypes.DELETE_NOTIFICATION_FROM_STACK:
      delete draft.notifications[action.id];
      draft.lastNotificationId = null;
      break;
  }
}, initialState);

export default notificationsReducer;
