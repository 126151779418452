import { FC } from "react";

import { SelectField } from "Components/fields";

import { useOwnerSelect } from "./add-report-form.hooks";
import type { IUserSelectFieldProps } from "./add-report-form.types";

export const UserSelectField: FC<IUserSelectFieldProps> = ({ fieldName, placeholder }) => {
  const { searchLoading, userOptions, onSearchOptions, onCheckEmails } = useOwnerSelect();

  return (
    <SelectField
      withEmailsCopy
      name={fieldName}
      placeholder={placeholder || "Select Users"}
      options={userOptions}
      searchLoading={searchLoading}
      onSearchChange={onSearchOptions}
      onCheckEmails={onCheckEmails}
    />
  );
};
