import { call, fork, ForkEffect, put, select, takeEvery } from "redux-saga/effects";

import NewsService from "SP/news";
import {
  addNewsFailure,
  addNewsSuccess,
  deleteNewsFailure,
  deleteNewsSuccess,
  editNewsFailure,
  editNewsSuccess,
  getNewsList as getNewsListAction,
  getNewsListFailure,
  getNewsListSuccess,
  IAddNewsRequestAction,
  IDeleteNewsRequestAction,
  IEditNewsRequestAction,
  IGetNewsListRequestAction,
  NewsActionTypes,
} from "Store/actions/news.actions";
import { notificationTypes, showNotification } from "Store/actions/notifications.actions";
import { IRootReducerState } from "Store/reducers";

const newsService = new NewsService();
const listLimitCount = 5; // TODO need to be read from config

export function* getNewsList(action: IGetNewsListRequestAction) {
  try {
    const nextPageNumber = action.page;
    let lastNewsInPageId;

    if (nextPageNumber > 1) {
      lastNewsInPageId = yield select((state: IRootReducerState) => state.news.pagination.pageLinks[nextPageNumber]);
    }

    const requestResult = yield call([newsService, "getAll"], { limit: action.limit, lastNewsInPageId });

    const news = requestResult.news;
    const isLastPage = requestResult.isLastPage;

    yield put(getNewsListSuccess(news, nextPageNumber, isLastPage));
  } catch (e: any) {
    yield put(getNewsListFailure(e));
    throw e;
  }
}

export function* watchGetNewsList() {
  yield takeEvery(NewsActionTypes.GET_NEWS_LIST_REQUEST, getNewsList);
}

export function* addNews(action: IAddNewsRequestAction) {
  try {
    yield call([newsService, "add"], action.payload);

    if (action.callback) {
      action.callback();
    }
    yield put(getNewsListAction({ limit: listLimitCount }));
    yield put(addNewsSuccess());
    yield put(
      showNotification({
        type: notificationTypes.success,
        text: "The news was successfully created ",
      }),
    );
  } catch (e: any) {
    yield put(addNewsFailure(e));
    throw e;
  }
}

export function* watchAddNews() {
  yield takeEvery(NewsActionTypes.ADD_NEWS_REQUEST, addNews);
}

export function* editNews(action: IEditNewsRequestAction) {
  try {
    yield call([newsService, "edit"], action.id, action.payload);

    if (action.callback) {
      action.callback();
    }
    yield put(getNewsListAction({ limit: listLimitCount }));
    yield put(editNewsSuccess());
    yield put(
      showNotification({
        type: notificationTypes.success,
        text: "The news was successfully edited",
      }),
    );
  } catch (e: any) {
    yield put(editNewsFailure(e));
    throw e;
  }
}

export function* watchEditNews() {
  yield takeEvery(NewsActionTypes.EDIT_NEWS_REQUEST, editNews);
}

export function* deleteNews(action: IDeleteNewsRequestAction) {
  try {
    yield call([newsService, "deleteById"], action.id);

    if (action.callback) {
      action.callback();
    }
    yield put(getNewsListAction({ limit: listLimitCount }));
    yield put(deleteNewsSuccess());
    yield put(
      showNotification({
        type: notificationTypes.success,
        text: "The news was successfully deleted",
      }),
    );
  } catch (e: any) {
    yield put(deleteNewsFailure(e));
    throw e;
  }
}

export function* watchDeleteNews() {
  yield takeEvery(NewsActionTypes.DELETE_NEWS_REQUEST, deleteNews);
}

export default function* newsSagas(): Iterator<ForkEffect> {
  yield fork(watchGetNewsList);
  yield fork(watchAddNews);
  yield fork(watchEditNews);
  yield fork(watchDeleteNews);
}
