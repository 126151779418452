import { ReactComponent as InfoIcon } from "@App/assets/table-info-outline-18.svg";
import { IconContainer, Tooltip } from "@epam/loveship";

export const CustomTooltip = ({ content, cx }: { content: string; cx?: string }): JSX.Element => {
  return (
    <Tooltip placement="top" content={content}>
      <IconContainer icon={InfoIcon} cx={`tooltip-icon ${cx}`} />
    </Tooltip>
  );
};

export const renderLabel = (label: string, info?: string, required = true): JSX.Element => (
  <>
    {label}
    {required && <span style={{ color: "red" }}>*</span>}
    {info && <CustomTooltip content={info} />}
  </>
);
