import { fork, ForkEffect, put, takeLatest } from "redux-saga/effects";

import TagsService from "SP/tags";
import { ITagWithGroup } from "SP/tags/tags.types";
import { groupTags } from "SP/tags/tags.utils";
import { getTagsFailure, getTagsSuccess, IGetTagsRequestAction, TagsActionTypes } from "Store/actions/tags.actions";

const tagsService = new TagsService();

function* loadTags(action: IGetTagsRequestAction) {
  try {
    const allTags: ITagWithGroup[] = yield tagsService.getAll();
    const groupedTags = groupTags(allTags);

    yield put(getTagsSuccess(groupedTags, allTags));
  } catch (error: any) {
    yield put(getTagsFailure(error));
    throw error;
  }
}

function* watchLoadTags() {
  yield takeLatest(TagsActionTypes.GET_TAGS_REQUEST, loadTags);
}

export default function* tagsSagas(): Iterator<ForkEffect> {
  yield fork(watchLoadTags);
}
