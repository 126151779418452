import qs, { ParsedQs } from "qs";

import type { ITimePeriod } from "Pages/reports-browser/reports-filters.reducer";

type IWindow = Window &
  typeof globalThis & {
    zaraz: { track: (eventName: string, properties?: any) => void };
  };

interface ISearchEventFilters {
  filterBy: string;
  role: string;
  businessDomain: string;
  application: string;
  metric: string;
  timePeriod: string;
  owner: string;
}

interface ISearchEventProps {
  searchTerm: string;
  searchResultsCount: number;
  filters: ISearchEventFilters;
}

export default {
  isProvideSearchData(): boolean {
    const { search, ...filters } = qs.parse(window.location.search, { ignoreQueryPrefix: true });

    const searchLength = (search?.length || 0) as number;
    return searchLength >= 3 || Object.keys(filters).some((key) => !!filters[key]);
  },
  handleTimeValue(value: ITimePeriod): string {
    if (!value) return undefined;

    return `${value?.fromDate ? `From - ${value?.fromDate}` : ""}${value?.toDate ? `;To - ${value?.toDate}` : ""}`;
  },
  handleArrayValues(value: string | ParsedQs | string[] | ParsedQs[]): string {
    if (!value) return undefined;

    return (Array.isArray(value) ? value.sort().join(";") : value) as string;
  },
  getGTMFilters(): ISearchEventFilters {
    const { search, ...filters } = qs.parse(window.location.search, { ignoreQueryPrefix: true }) as any;

    const gtmFilters: ISearchEventFilters = {
      filterBy: this.handleArrayValues(filters?.filterBy),
      role: this.handleArrayValues(filters?.Role),
      businessDomain: this.handleArrayValues(filters?.["Business Domain"]),
      application: this.handleArrayValues(filters?.Application),
      metric: this.handleArrayValues(filters?.Metric),
      timePeriod: this.handleTimeValue(filters.timePeriod),
      owner: this.handleArrayValues(filters?.Owner),
    };

    return gtmFilters;
  },
  provideSearchData(searchTerm: string, searchResultsCount: number): void {
    const searchEventProps: ISearchEventProps = {
      searchTerm: searchTerm || undefined,
      searchResultsCount,
      filters: this.getGTMFilters(),
    };

    (window as IWindow).zaraz?.track("search", searchEventProps);
  },
};
