import Routes from "@App/routes";
import { ICanRedirect } from "@epam/uui-core";

import { POWER_BI_GUIDE_LINK, REQUEST_NEW_REPORT_LINK } from "Helpers/constants";
import { handleReloadPageWithTimeout } from "Helpers/errorHandler";

type IHeaderButton = ICanRedirect & {
  caption: string;
  className?: string;
};

export const headerButtons: IHeaderButton[] = [
  {
    caption: "Dashboard",
    link: { pathname: Routes.HOME.path },
  },
  {
    caption: "All reports",
    link: { pathname: Routes.REPORTS_BROWSER.path },
  },
  {
    caption: "Power BI guide",
    target: "_blank",
    className: "external-link",
    href: POWER_BI_GUIDE_LINK,
  },
  {
    caption: "Request new report",
    target: "_blank",
    className: "external-link",
    href: REQUEST_NEW_REPORT_LINK,
  },
];

export const getRedirectProp = (isGlobalErrorThrow: boolean) => {
  return (
    isGlobalErrorThrow && {
      onClick: handleReloadPageWithTimeout,
    }
  );
};

export const onBurgerButtonClick = (onClose: () => void, isGlobalErrorThrow: boolean) => () => {
  onClose();

  if (isGlobalErrorThrow) {
    handleReloadPageWithTimeout();
  }
};
