import { FC, memo, useEffect, useRef } from "react";

import Routes from "@App/routes";
import { LinkButton, Spinner } from "@epam/loveship";

import { Accordion } from "Components/accordion";
import type { IAccordionHandle } from "Components/accordion/accordion";
import withNaming from "Helpers/bemClassname";
import { useDashboardFavorites } from "Pages/dashboard/dashboard.hooks";
import type { IReport } from "SP/reports/reports.types";

import { FavoriteReport } from "../dashboard-favorites-block/dashboard-favorites-block-report";

import "./dashboard-section.scss";

export const cn = withNaming("dashboard-section");
const MAX_REPORTS_TO_SHOW = 6;

interface IDashboardSectionProps {
  loading: boolean;
  reports: IReport[];
  title: string;
  allReportsFilter: string;
}

export const DashboardSection: FC<IDashboardSectionProps> = memo(
  ({ loading, reports = [], title, allReportsFilter }) => {
    const accordionRef = useRef<IAccordionHandle>();
    const { isLoaded } = useDashboardFavorites();

    useEffect(() => {
      if (isLoaded) {
        accordionRef.current.handleSetIsExpanded(reports.length > 0);
      } else {
        accordionRef.current.handleSetIsExpanded(true);
      }
    }, [isLoaded, reports.length]);

    return (
      <Accordion
        defaultExpanded
        isDisabled={isLoaded && !reports.length}
        ref={accordionRef}
        badgeCount={reports.length}
        title={title}
        allReportsFilter={allReportsFilter}
        renderTitle={(title, isDisabled) => (
          <LinkButton
            cx={cn("title", { isDisabled }, ["unstyled-link"])}
            caption={title}
            link={{
              pathname: Routes.REPORTS_BROWSER.path,
              search: allReportsFilter,
            }}
          />
        )}
      >
        <div className={cn("", ["inline-flex flex-wrap"])}>
          {reports.slice(0, MAX_REPORTS_TO_SHOW).map((report) => (
            <FavoriteReport key={`${title}-${report.id}`} report={report} disabled fullCard />
          ))}
          {loading && <Spinner cx={cn("loader")} />}
        </div>
      </Accordion>
    );
  },
);
