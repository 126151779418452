import { FC, useMemo } from "react";

import { PickerInput, SearchInput } from "@epam/loveship";
import { useArrayDataSource } from "@epam/uui-core";

import withNaming from "Helpers/bemClassname";
import { dashboardDefaultRole, handleRolesData, IRole } from "Helpers/utils";
import { useAppSelector } from "Hooks/useAppSelector";
import { getSpecificRoleNamesOrder } from "Hooks/useReportsSort";

import { useDashboardReportsMethods } from "../dashboard.hooks";
import { FilterRoleToggler } from "./filter-role-toggler/filter-role-toggler";

import "./dashboard-domain-filters.scss";

export enum DashboardFilterTabs {
  certified = "certified",
  favorite = "favorite",
}

interface IDashboardDomainFiltersProps {
  roles: IRole[];
}

export function isNotDefaultFilterRole(filterRoles: string[]): boolean {
  return filterRoles?.length && !filterRoles.find((filterRole) => filterRole === dashboardDefaultRole.id);
}

const cn = withNaming("dashboard-domain-filters");

const DashboardDomainFilters: FC<IDashboardDomainFiltersProps> = ({ roles }) => {
  const filterRoles = useAppSelector((state) => state.dashboard.filterRoles);
  const loading = useAppSelector((state) => state.dashboard.loading);
  const { setFilterRoles } = useDashboardReportsMethods();

  const dataSource = useArrayDataSource(
    {
      items: handleRolesData(roles),
    },
    [roles],
  );

  const value = useMemo(() => [...filterRoles].sort(getSpecificRoleNamesOrder), [filterRoles]);

  return (
    <div className={cn("", ["flex justify-end"])}>
      <PickerInput
        searchPosition="body"
        entityName="Role"
        dropdownPlacement="bottom-start"
        dataSource={dataSource}
        value={value}
        onValueChange={setFilterRoles}
        getName={(item) => item.label}
        selectionMode="multi"
        valueType="id"
        renderToggler={(props) => (
          <SearchInput
            {...props}
            cx={cn("input")}
            value={props.value ?? ""}
            onValueChange={props.onValueChange}
            renderInput={() => (
              <FilterRoleToggler
                selectionLength={props.selection?.length}
                placeholder={props.placeholder}
                label={props.selection?.[0]?.value?.label}
                loading={loading}
              />
            )}
          />
        )}
      />
    </div>
  );
};

export default DashboardDomainFilters;
