import { FC } from "react";

import { ReactComponent as CommunicationThumbUpFillIcon } from "@epam/assets/icons/common/social-thumb-up-18.svg";

import Badge, { BadgeColors } from "Components/badge";
import { BadgeDescriptionTooltip } from "Components/badges/badges-description-tooltip";

import type { IBadge } from "./report-details-helpers";
import ReportDetailsRow from "./report-details-row";

interface IReportDetailsBadgesProps {
  label: string;
  badges: IBadge[];
  color?: BadgeColors;
}

const ReportDetailsBadges: FC<IReportDetailsBadgesProps> = ({ label, badges, color }) => {
  return (
    <ReportDetailsRow label={label} labelAlignCx="--align-center">
      <div className="report-badges">
        {badges.map((badge) => (
          <BadgeDescriptionTooltip key={badge.id} description={badge.description} size="large">
            <Badge color={color}>
              {badge.thumbUp && <CommunicationThumbUpFillIcon />}
              <span className="report-badge--label">{badge.label}</span>
            </Badge>
          </BadgeDescriptionTooltip>
        ))}
      </div>
    </ReportDetailsRow>
  );
};

export default ReportDetailsBadges;
