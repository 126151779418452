import * as Yup from "yup";

import { INewsSavePayload } from "SP/news/news.types";

const REQUIRED_MESSAGE = "This field is required to fill";
const INCORRECT_URL_MESSAGE = "The link must be correct";

export const PROTOCOLS = ["http://", "https://", "ftp://"];

export const addProtocol = (value: string) => {
  if (!value) return value;

  const doesNotStartWithProtocol = !PROTOCOLS.find((i) => value.startsWith(i));

  return doesNotStartWithProtocol ? `https://${value}` : value;
};

const newsSchema = Yup.object<INewsSavePayload>().shape({
  newsTitle: Yup.string().trim().required(REQUIRED_MESSAGE),
  description: Yup.string().trim().required(REQUIRED_MESSAGE),
  reportLink: Yup.lazy((value) => {
    if (!value) {
      return Yup.mixed().notRequired();
    }
    return Yup.string().url(INCORRECT_URL_MESSAGE).transform(addProtocol).required(REQUIRED_MESSAGE);
  }),
});

export default newsSchema;
