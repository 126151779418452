import { FC } from "react";

import { Tooltip } from "Components/tooltip";
import { useMobileData } from "Hooks/useMobile";

import { badgesCN } from "./badges";
import type { IDescriptionTooltip } from "./badges.types";

export const BadgeDescriptionTooltip: FC<IDescriptionTooltip> = ({ description, children, size }) => {
  const { isMobile } = useMobileData();

  return (
    <Tooltip
      trigger={isMobile ? "click" : "hover"}
      placement="bottom-start"
      color="white"
      cx={badgesCN("description-tooltip", { [size]: !!size })}
      content={() => (
        <div className={badgesCN("description-panel")}>{description || "Tag description is in progress"}</div>
      )}
    >
      {children}
    </Tooltip>
  );
};
